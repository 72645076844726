import firebase from 'firebase'
import { RETRIEVE_ORGANIZATION } from "../constants/functions"

export async function verifyOrganizationCode(code = '') {
  // Uncomment for testing with Firebase Emulators
  // firebase.functions().useFunctionsEmulator('http://localhost:5000')
  const fn = firebase.functions().httpsCallable(RETRIEVE_ORGANIZATION);

  try {
    const { data } = await fn({ code: code.trim() })

    return data;

  } catch (e) {
    console.log(e.message)
    return null
  }

}

export async function getOrganization(id = '') {
  // Uncomment for testing with Firebase Emulators
  // firebase.functions().useFunctionsEmulator('http://localhost:5000')
  const fn = firebase.functions().httpsCallable(RETRIEVE_ORGANIZATION);

  try {
    const { data } = await fn({ id: id.trim() })

    return data;

  } catch (e) {
    console.log(e.message)
    return null
  }

}
