import React from 'react'

const PageContext = React.createContext()

function reducer(state, action) {
  const { type, payload } = action
  switch (type) {
    case 'UPDATE_CURRENT_PAGE':
      const currentPage =
        !payload || payload === '/' ? 'home' : payload.replace('/', '')
      return { ...state, currentPage }
    default:
      throw new Error('Invalid Action')
  }
}

function PageProvider(props) {
  const { path, children } = props
  const [state, dispatch] = React.useReducer(reducer, {
    currentPage: path === '/' ? 'home' : path.replace('/', '')
  })

  return (
    <PageContext.Provider value={[state, dispatch]} {...props}>
      {children}
    </PageContext.Provider>
  )
}

function usePage() {
  const context = React.useContext(PageContext)
  if (context === undefined) {
    throw new Error(`usePage must be used with a PageProvider`)
  }
  return context
}

export { PageProvider, usePage }
