import React, { useContext } from 'react'
import firebase from 'firebase'
import { shopifyClient } from './shopify'
import RegistryClient from '../utils/registryClient'

const registryClient = new RegistryClient(firebase, shopifyClient)

const RegistryContext = React.createContext(registryClient)

function RegistryProvider(props) {
  return (
    <RegistryContext.Provider value={registryClient}>
      {props.children}
    </RegistryContext.Provider>
  )
}

function useRegistry() {
  const context = useContext(RegistryContext)
  if (context === undefined) {
    throw new Error(`useShopify must be used within a ShopifyProvider`)
  }

  return context
}

export { RegistryProvider, useRegistry }
