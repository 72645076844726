// @flow
import React, { useState, useEffect, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import TextField from '@material-ui/core/TextField'
import CssBaseline from '@material-ui/core/CssBaseline'
import { auth } from '../../firebase'
import * as routes from '../../constants/routes'
import { getLocation } from '../../functions/getLocation'
import { LinkContainer } from 'react-router-bootstrap'
import Container from '@material-ui/core/Container'
import Avatar from '@material-ui/core/Avatar'
import backgroundImage from '../../images/background-login.png'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import { Wizard, Steps, Step } from 'react-albus'
import { useAuth } from '../../context/auth'
import { createUser, addGroupMember } from '../../functions/user'
import { sendSignupNotification } from '../../functions/sendSignupNotification'
import Typography from '@material-ui/core/Typography'

import Terms from './Terms'
import Privacy from './Privacy'
import { Mixpanel } from '../../functions/mixpanel'
import { USER_GROUPS } from '../../constants/groups'
import Select from '@material-ui/core/Select'
import FormHelperText from '@material-ui/core/FormHelperText'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import { isValidEmail, validateDomain } from '../../functions/validEmail'
import debounce from 'lodash.debounce'
import {
  CircularProgress,
  InputLabel,
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  List,
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox'
import { strengthIndicator } from './PasswordStrength'
import { generateToggledFunction } from '../../utils/misc'
import { verifyOrganizationCode } from '../../functions/organizationUtils'
import OrganizationBlock from '../OrganizationBlock'

const SignUpPage = ({ location, history }) => (
  <SignUpForm location={location} history={history} />
)

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: '#E5E5E5',
      fontSize: 14,
      '& label.Mui-focused': {
        color: theme.palette.primary.main,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: theme.palette.primary.main,
      },
      '& .MuiFormControlLabel-label.Mui-disabled': {
        color: 'grey',
      },
      '& .password-strength-meter': {
        textAlign: 'left',
      },

      '& .password-strength-meter-progress': {
        '& .-webkit-appearance': 'none',
        appearance: 'none',
        width: '250px',
        height: '8px',
      },
      '& .password-strength-meter-progress::-webkit-progress-bar': {
        backgroundColor: '#eee',
        borderRadius: '3px',
      },
      '& .password-strength-meter-progress::-webkit-progress-value': {
        borderRadius: '2px',
        backgroundSize: '35px 20px, 100% 100%, 100% 100%',
      },

      '& .strength-Weak::-webkit-progress-value': {
        backgroundColor: '#FF0000',
      },
      '& .strength-Fair::-webkit-progress-value': {
        backgroundColor: '#FFFF00',
      },
      '& .strength-Good::-webkit-progress-value': {
        backgroundColor: '#0000FF',
      },
      '& .strength-Strong::-webkit-progress-value': {
        backgroundColor: '#00FF00',
      },
    },
  },
  root: {
    background: props =>
      props.color === 'red'
        ? 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)'
        : 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
    backgroundColor: '#E5E5E5',
    border: 0,
    borderRadius: 3,
    boxShadow: 'none',
    color: 'white',
    padding: '0 30px',
    margin: 8,
  },
  backButton: {
    margin: theme.spacing(1, 0, 2),
    borderRadius: '50px',
    fontSize: '1.6rem',
    '&:hover': {
      backgroundColor: '#f8f8f8',
      color: theme.palette.primary.main,
    },
    display: 'flex',
    border: `1px solid ${theme.palette.primary.main}`,
    color: '#9A9A9A',
  },
  button: {
    margin: theme.spacing(1, 0, 2),
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50px',
    fontSize: '1.6rem',
    '&:hover': {
      backgroundColor: '#c18135',
      color: theme.palette.common.white,
    },
    display: 'flex',
  },
  buttonLabel: {
    margin: 'auto',
    display: 'flex',
  },
  bigAvatar: {
    margin: theme.spacing(1),
    backgroundColor: 'white',
    width: 160,
    height: 160,
    padding: '5px',
  },
  companyName: {
    fontSize: '4.0rem',
    color: theme.palette.primaryCompliment.main,
  },
  errors: {
    marginTop: theme.spacing(2),
    color: 'red',
    textAlign: 'left',
  },
  emailInput: {
    height: '1.5em',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: '10px',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  formGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
  fullName: {
    color: theme.palette.primaryCompliment.main,
  },
  header: {
    color: theme.palette.primaryCompliment.main,
  },
  image: {
    display: 'flex',
    justifyContent: 'center',
  },
  input: {
    boxShadow: 'none',
  },
  invalidIcon: {
    color: theme.palette.error.main,
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: '20px 10px',
    paddingTop: '100px',
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    width: '100%',
  },
  phoneInput: {
    boxShadow: 'none',
    font: 'inherit',
    color: 'black',
    width: '100%',
    border: 0,
    height: '1.1875em',
    margin: 0,
    display: 'block',
    padding: '6px 0 7px',
    minWidth: 0,
    background: 'none',
    boxSizing: 'content-box',
  },
  signInLink: {
    color: theme.palette.primaryHighlight.main,
  },
  subHeader: {
    color: theme.palette.primary.main,
  },
  title: {
    margin: '15px 0',
    color: theme.palette.primaryCompliment.main,
    fontSize: '18px',
  },
  validIcon: {
    color: theme.palette.success.main,
  },
}))

const NextButton = (props: { disabled: boolean, next: Function }) => {
  const classes = useStyles()
  const { disabled, next } = props
  return (
    <Button
      className={classNames('btn-fluid', 'margin-1-b', classes.button)}
      type="button"
      fullWidth
      disabled={disabled}
      onClick={next}
      data-cy="next-button">
      NEXT
    </Button>
  )
}

const PreviousButton = (props: { disabled: boolean, previous: Function }) => {
  const classes = useStyles()
  const { previous, disabled } = props
  return (
    <Button
      className={`${classNames('btn-fluid', 'margin-1-b', 'btn-secondary')} ${
        classes.backButton
      }`}
      type="button"
      fullWidth
      disabled={disabled}
      onClick={previous}
      data-cy="previous-button">
      BACK
    </Button>
  )
}

const SignUpForm = (props: Object) => {
  const classes = useStyles()
  const { history, location } = props
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [organizationCode, setOrganizationCode] = useState('')
  const [lat, setLat] = useState('')
  const [lng, setLng] = useState('')
  const [passwordOne, setPasswordOne] = useState('')
  const [passwordTwo, setPasswordTwo] = useState('')
  const [error, setError] = useState(null)
  const [agreedToTerms, setAgreedToTerms] = useState(false)
  const [open, setOpen] = useState(false)
  const [privacyOpen, setPrivacyOpen] = useState(false)
  const data = location.pathname.substring(8)
  const [supports, setSupports] = useState()
  const [supportGroupType, setSupportGroupType] = useState('support')
  const [isPatient, setIsPatient] = useState(data.length <= 0 ? 'yes' : 'no')
  const [isValidPassword, setIsValidPassword] = React.useState(false)
  const [group, setGroup] = useState('')
  const [providedSupporterCode, setProvidedSupportedCode] = useState(false)
  const [creatingUser, setCreatingUser] = useState(false)
  // Form Validation
  const [showStepErrors, setShowStepErrors] = useState(false)
  const [maxStep, setMaxStep] = useState(0)

  //Stronger Password
  const passwordStrength = strengthIndicator(passwordOne)

  const handleNext = (next, current) => () => {
    // Set maxStep if current step is furthest step
    if (maxStep + 1 === current) {
      setMaxStep(maxStep + 1)
      setShowStepErrors(false)
    }

    next()
  }

  const handleInputChange = onChange => e => {
    onChange(e)
    setShowStepErrors(true)
  }
  // Indicates whether the entered email belongs to an existing user
  const [emailValid, setEmailValid] = useState({
    ok: false,
    available: false,
    validDomain: false,
  })

  // Indicates whether the email verification request is complete
  const [emailVerified, setEmailVerified] = useState(false)

  // Indicates if the email verification request is in progress
  const [emailVerifying, setEmailVerifying] = useState(false)

  const [organization, setOrganization] = useState(null)

  // Indicates whether the entered code is from an existing organization
  const [organizationValid, setOrganizationValid] = useState(false)

  // Indicates whether the organization code verification request is complete
  const [organizationVerified, setOrganizationVerified] = useState(false)

  // Indicates if the organization code verification request is in progress
  const [organizationVerifying, setOrganizationVerifying] = useState(false)

  useEffect(() => {
    if (data) {
      const supported = JSON.parse(atob(data))
      const { id, type } = supported
      setSupports(id)
      setSupportGroupType(type)
      setProvidedSupportedCode(true)
    }
  }, [data])

  const handleClose = () => {
    setOpen(false)
  }

  const handlePrivacyClose = () => {
    setPrivacyOpen(false)
  }

  const { login } = useAuth()

  useEffect(() => {
    getUserLocation()
  }, [])

  const handleIsPatient = (e: Event) => {
    setIsPatient(e.target.value)
  }

  // Agree to terms and conditions
  const handleAgree = e => {
    setAgreedToTerms(e.target.checked)
  }

  // Show terms and conditions modal
  const handleTerms = (e: Event) => {
    e.preventDefault()
    setOpen(true)
  }

  const handlePrivacy = (e: Event) => {
    e.preventDefault()
    setPrivacyOpen(true)
  }

  const handleGroupSelect = (e: Event) => {
    setGroup(e.target.value)
  }
  // Get user's current location
  const getUserLocation = async () => {
    try {
      let result = await getLocation()
      if (result) {
        setLat(result.lat)
        setLng(result.lon)
      }
    } catch (error) {
      return error
    }
  }
  //Stronger Password
  const createPasswordLabel = result => {
    switch (result) {
      case 0:
        return 'Weak'
      case 1:
        return 'Weak'
      case 2:
        return 'Fair'
      case 3:
        return 'Good'
      case 4:
        return 'Strong'
      default:
        return 'Weak'
    }
  }

  const onSubmit = async e => {
    e.preventDefault()

    if (passwordOne.length < 6) {
      setError(`Password must be at least 6 characters`)
      return
    }

    setCreatingUser(true)
    await auth
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        createUser(
          authUser.user.uid,
          firstName.trim(),
          lastName.trim(),
          email.trim(),
          lat,
          lng,
          isPatient,
          supports,
          group,
          organization ? organization.id : null
        )
          .then(async () => {
            if (supports) {
              await addGroupMember(
                supportGroupType,
                supports,
                authUser.user.uid
              )
            }
            await sendSignupNotification(email, firstName, lastName)
            Mixpanel.identify(authUser.user.uid)
            Mixpanel.track('New User Signup')
            Mixpanel.people.set({
              $first_name: firstName,
              $last_name: lastName,
              $email: email,
              $group: group,
            })

            login(email, passwordOne)
            window.location = routes.HOME
          })
          .catch(error => {
            setError(error)
          })
      })
      .catch(error => {
        setError(error)
        setCreatingUser(false)
      })

    e.preventDefault()
  }

  // Verify the email entered
  // Prevent excessive email verification request throttling by debouncing calls
  const verifyEmail = useCallback(
    debounce(async email => {
      setEmailVerifying(true)

      let validDomain = false
      const isUser = await auth.isExistingUser(email)

      if (!isUser) {
        validDomain = await validateDomain(email)
      }

      setEmailValid({
        ok: !isUser && validDomain,
        available: !isUser,
        validDomain,
      })

      setEmailVerified(true)

      setEmailVerifying(false)
    }, 800),
    []
  )

  const handleOrganizationChange = ({ target: { value: code = '' } }) => {
    setOrganizationCode(code)

    // Invalidate organization verification
    setOrganizationVerified(false)

    // Verify organization code only if a non-empty string was entered
    if (code.length > 0) verifyOrganization(code)
  }

  const handleEmailChange = ({ target: { value: email } }) => {
    setEmail(email)

    // Invalidate email verification
    setEmailVerified(false)

    // Verify email does not belong to a user only if the email is syntactically valid
    if (isValidEmail(email)) verifyEmail(email)
  }

  const isInvalid =
    firstName === '' ||
    lastName === '' ||
    passwordOne !== passwordTwo ||
    passwordOne.length < 6 ||
    group === '' ||
    passwordOne === '' ||
    email === '' ||
    agreedToTerms === false

  useEffect(() => {
    setIsValidPassword(
      passwordOne.length > 5 &&
        passwordOne === passwordTwo &&
        (RegExp(/[0-9]/).test(passwordOne) ||
          RegExp(/[!#@$%^&*)(+=._-]/).test(passwordOne) ||
          (RegExp(/[a-z]/).test(passwordOne) &&
            RegExp(/[A-Z]/).test(passwordOne)))
    )
  }, [passwordOne, passwordTwo])

  const verifyOrganization = useCallback(
    debounce(async code => {
      setOrganizationVerifying(true)

      const match = await verifyOrganizationCode(code)

      setOrganizationValid(Boolean(match))

      setOrganization(match)

      setOrganizationVerified(true)

      setOrganizationVerifying(false)
    }, 800),
    []
  )

  return (
    <Container component="main" maxWidth="xs" history={history}>
      <CssBaseline />
      <div className="signup-container">
        <div className={classes.paper}>
          <form className={classes.form}>
            <Wizard>
              <Steps>
                <Step
                  id="create-account"
                  render={({ next }) => (
                    <>
                      <Box textAlign="center">
                        <Typography
                          className={classes.companyName}
                          component="h1"
                          align="center"
                          variant="h5">
                          <b>Family Proud</b>
                        </Typography>
                        <div className={classes.image}>
                          <Avatar
                            className={classes.bigAvatar}
                            src={require('../../images/logo-brand-multi.png')}
                          />
                        </div>

                        <h2 className={classes.header}>Sign Up</h2>
                        <p className="hint-text">
                          Create your account. It's free and only takes a
                          minute.
                          <br />
                          Have an account already?{' '}
                          <LinkContainer to={routes.SIGN_IN_USER}>
                            <span className={classes.signInLink}>Sign in</span>
                          </LinkContainer>
                          <br />
                          <span id="required-field-text">
                            Fields with{' '}
                            <span className="required-field">*</span> are
                            required.
                          </span>
                        </p>
                      </Box>

                      <NextButton disabled={false} next={handleNext(next, 0)} />
                    </>
                  )}
                />
                <Step
                  id="full-name"
                  render={({ next, previous }) => (
                    <div className={classes.fullName}>
                      <b>
                        Full Name <span className="required-field">*</span>
                      </b>
                      <div className="row">
                        <div className="col-xs-12">
                          <TextField
                            required
                            type="text"
                            value={firstName}
                            className={`form-control ${classes.input}`}
                            placeholder="First Name"
                            margin="normal"
                            fullWidth
                            id="firstName"
                            label="First Name"
                            name="firstName"
                            autoComplete="firstName"
                            autoFocus
                            onChange={handleInputChange(event =>
                              setFirstName(event.target.value)
                            )}
                          />
                        </div>
                        <div className="col-xs-12">
                          <TextField
                            required
                            type="text"
                            value={lastName}
                            className={`form-control ${classes.input}`}
                            placeholder="Last Name"
                            margin="normal"
                            fullWidth
                            id="lastName"
                            label="Last Name"
                            name="lastName"
                            autoComplete="lastName"
                            onChange={handleInputChange(event =>
                              setLastName(event.target.value)
                            )}
                          />
                        </div>
                        <div className="col-xs-12">
                          {showStepErrors ? (
                            <ul className={classes.errors}>
                              {firstName === '' && (
                                <li>First name cannot be blank</li>
                              )}
                              {lastName === '' && (
                                <li>Last name cannot be blank</li>
                              )}
                            </ul>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                      <NextButton
                        disabled={firstName === '' || lastName === ''}
                        next={handleNext(next, 1)}
                      />
                      <PreviousButton previous={previous} />
                    </div>
                  )}
                />
                <Step
                  id="email-address"
                  render={({ next, previous }) => (
                    <>
                      <div className="form-group">
                        <TextField
                          required
                          type="email"
                          value={email}
                          className={`form-control ${classes.input}`}
                          placeholder="Email Address"
                          margin="normal"
                          fullWidth
                          id="exampleEmail"
                          data-cy="email-input"
                          label="Email Address"
                          name="email"
                          autoComplete="email"
                          autoFocus
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: showStepErrors ? (
                              emailVerifying ? (
                                <CircularProgress size={'1.2em'} />
                              ) : emailValid.ok && emailVerified ? (
                                <CheckCircleIcon
                                  data-cy="valid-email-indicator"
                                  className={classes.validIcon}
                                />
                              ) : (!emailValid.ok && emailVerified) ||
                                !isValidEmail(email) ? (
                                <CancelIcon
                                  data-cy="invalid-email-indicator"
                                  className={classes.invalidIcon}
                                />
                              ) : (
                                ''
                              )
                            ) : (
                              ''
                            ),
                            classes: { input: classes.emailInput },
                          }}
                          onChange={handleInputChange(handleEmailChange)}
                        />
                      </div>
                      <ul className={classes.errors}>
                        {showStepErrors ? (
                          !isValidEmail(email) ? (
                            <li>Must be a valid email address</li>
                          ) : emailVerified && !emailValid.available ? (
                            <li>An account already exists with this email</li>
                          ) : emailVerified && !emailValid.validDomain ? (
                            <li>
                              The email entered does not have a valid domain
                            </li>
                          ) : (
                            ''
                          )
                        ) : (
                          ''
                        )}
                      </ul>
                      <NextButton
                        disabled={!emailValid.ok || !emailVerified}
                        next={handleNext(next, 2)}
                        data-cy="Next"
                      />
                      <PreviousButton previous={previous} />
                    </>
                  )}
                />
                <Step
                  id="password"
                  render={({ next, previous }) => (
                    <>
                      <div className="form-group">
                        <TextField
                          value={passwordOne}
                          required
                          type="password"
                          minLength="6"
                          pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$"
                          title="Please include at least 1 uppercase character, 1 lowercase character, and 1 number."
                          className={`form-control ${classes.input}`}
                          id="examplePassword"
                          placeholder="Password"
                          margin="normal"
                          fullWidth
                          data-cy="password-input-original"
                          name="examplePassword"
                          autoComplete="examplePassword"
                          autoFocus
                          onChange={handleInputChange(event =>
                            setPasswordOne(event.target.value)
                          )}
                        />
                      </div>
                      {passwordOne && (
                        <div className="password-strength-meter">
                          <progress
                            className={`password-strength-meter-progress strength-${createPasswordLabel(
                              passwordStrength
                            )}`}
                            value={passwordStrength}
                            max="4"
                          />{' '}
                          <label className="password-strength-meter-label">
                            {createPasswordLabel(passwordStrength)}
                          </label>
                        </div>
                      )}
                      <div className="form-group">
                        <TextField
                          value={passwordTwo}
                          required
                          type="password"
                          minLength="6"
                          className={`form-control ${classes.input}`}
                          id="examplePassword1"
                          placeholder="Confirm Password"
                          margin="normal"
                          fullWidth
                          data-cy="password-input-match"
                          name="examplePassword1"
                          autoComplete="examplePassword1"
                          onChange={handleInputChange(event =>
                            setPasswordTwo(event.target.value)
                          )}
                        />
                      </div>
                      <div className="help-block"></div>
                      {!isValidPassword && showStepErrors && (
                        <ul className={classes.errors}>
                          <FormControl
                            required
                            error={error}
                            component="fieldset"
                            className={classes.formControl}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={!(passwordOne.length < 6)}
                                    disabled="false"
                                  />
                                }
                                label="A minimum of six (6) characters"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      !(passwordOne !== passwordTwo) &&
                                      passwordOne !== ''
                                    }
                                    disabled="false"
                                  />
                                }
                                label="Passwords must match"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={RegExp(/[0-9]/).test(passwordOne)}
                                    disabled="false"
                                  />
                                }
                                label="Numbers (0-9)"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      RegExp(/[a-z]/).test(passwordOne) &&
                                      RegExp(/[A-Z]/).test(passwordOne)
                                    }
                                    disabled="false"
                                  />
                                }
                                label="Lowercase[a-z] and Uppercase[A-Z] letters"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={RegExp(/[!#@$%^&*)(+=._-]/).test(
                                      passwordOne
                                    )}
                                    disabled="false"
                                  />
                                }
                                label="Symbols or special characters ($%@#!)"
                              />
                            </FormGroup>
                          </FormControl>
                        </ul>
                      )}
                      <NextButton
                        disabled={!isValidPassword}
                        next={handleNext(next, 3)}
                      />
                      <PreviousButton previous={previous} />
                    </>
                  )}
                />
                <Step
                  id="patient"
                  render={({ next, previous }) => (
                    <>
                      <Box textAlign="center">
                        <div className={`form-group ${classes.formGroup}`}>
                          <label>
                            <p className={classes.title}>
                              We are stronger together!
                            </p>
                            <FormControl component="fieldset" fullWidth>
                              <RadioGroup
                                name="patient"
                                value={isPatient}
                                onChange={handleInputChange(handleIsPatient)}>
                                <FormControlLabel
                                  control={<Radio color="primary" />}
                                  label="I am here to receive support"
                                  value="yes"
                                />
                                <FormControlLabel
                                  control={<Radio color="primary" />}
                                  label="I am here to support others"
                                  value="no"
                                />
                              </RadioGroup>
                            </FormControl>
                          </label>
                        </div>
                        {isPatient !== 'yes' && (
                          <div className="form-group">
                            <p>
                              If you have a supporter code (see your invitation
                              email), please enter it below.
                            </p>
                            <TextField
                              value={supports}
                              className={classNames(
                                'form-control',
                                classes.input
                              )}
                              id="supporterCode"
                              placeholder="Supporter Code"
                              margin="normal"
                              fullWidth
                              name="supporterCode"
                              onChange={handleInputChange(event =>
                                setSupports(event.target.value)
                              )}
                              disabled={providedSupporterCode}
                            />
                          </div>
                        )}
                      </Box>

                      <NextButton next={handleNext(next, 4)} />
                      <PreviousButton previous={previous} />
                    </>
                  )}
                />
                <Step
                  id="group-organization"
                  render={({ next, previous }) => (
                    <>
                      <Box mt={2} mb={2}>
                        <div>
                          <InputLabel shrink>Group</InputLabel>
                          <Select
                            fullWidth
                            value={group}
                            onChange={handleInputChange(handleGroupSelect)}
                            required>
                            {USER_GROUPS.map((item, idx) => {
                              return (
                                <MenuItem key={idx} value={item}>
                                  {item}
                                </MenuItem>
                              )
                            })}
                          </Select>
                          <FormHelperText>Select Your Group</FormHelperText>
                        </div>
                        <div>
                          <TextField
                            value={organizationCode}
                            className={`form-control ${classes.input}`}
                            placeholder="Enter your organization code"
                            helperText={
                              organizationVerified
                                ? organizationValid
                                  ? 'Access Code Verified!'
                                  : 'Access Code Invalid'
                                : ''
                            }
                            error={!organizationValid && organizationVerified}
                            margin="normal"
                            fullWidth
                            data-cy="organization-input"
                            label="Organization Code"
                            name="organization"
                            autoFocus
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              endAdornment: organizationVerifying ? (
                                <CircularProgress size={'1.2em'} />
                              ) : organizationValid && organizationVerified ? (
                                <CheckCircleIcon
                                  className={classes.validIcon}
                                />
                              ) : !organizationValid && organizationVerified ? (
                                <CancelIcon className={classes.invalidIcon} />
                              ) : (
                                ''
                              ),
                              classes: { input: classes.emailInput },
                            }}
                            onChange={handleOrganizationChange}
                          />
                        </div>
                        <Box mb={1} mt={5}>
                          {organization ? (
                            <OrganizationBlock
                              organization={organization}
                              checked
                            />
                          ) : (
                            ''
                          )}
                        </Box>
                        {showStepErrors ? (
                          <ul className={classes.errors}>
                            {group === '' && <li>Please select a group</li>}
                          </ul>
                        ) : (
                          ''
                        )}
                      </Box>
                      <NextButton
                        disabled={group === ''}
                        next={handleNext(next, 5)}
                      />
                      <PreviousButton previous={previous} />
                    </>
                  )}
                />
                <Step
                  id="terms"
                  render={({ _, previous }) => (
                    <>
                      <p>
                        Please read through our terms of use and privacy policy
                      </p>
                      <div className="terms-container">
                        <label>
                          <input
                            type="checkbox"
                            name="checkbox"
                            checked={agreedToTerms}
                            onChange={handleInputChange(handleAgree)}
                            id="agree"
                          />{' '}
                          I agree to the Family Proud{' '}
                          <a
                            className="sign-up-link-click"
                            onClick={handleInputChange(handleTerms)}>
                            terms &amp; conditions of use{' '}
                          </a>{' '}
                          and{' '}
                          <a
                            className="sign-up-link-click"
                            onClick={handleInputChange(handlePrivacy)}>
                            privacy policy
                          </a>
                          .
                        </label>
                        <p />
                      </div>
                      <button
                        className={classNames('btn', classes.button)}
                        disabled={isInvalid || creatingUser}
                        type="submit"
                        onClick={generateToggledFunction(
                          onSubmit,
                          creatingUser
                        )}>
                        <span className={classes.buttonLabel}>Submit</span>
                      </button>
                      <PreviousButton
                        previous={generateToggledFunction(
                          previous,
                          creatingUser
                        )}
                      />
                    </>
                  )}
                />
              </Steps>
            </Wizard>
          </form>
        </div>
      </div>
      <Terms open={open} handleClose={handleClose} />
      <Privacy open={privacyOpen} handleClose={handlePrivacyClose} />
    </Container>
  )
}

export default withRouter(SignUpPage)
export { SignUpForm }
