// @flow
import React from 'react'
import { withRouter } from 'react-router-dom'
import { auth, analytics } from '../../firebase'
import * as routes from '../../constants/routes'
import firebase from 'firebase'
import { useGeolocation } from 'react-use'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'

import backgroundImage from '../../images/background-login.png'
import { useAuth } from '../../context/auth'
import { useUser } from '../../context/user'
import { Mixpanel } from '../../functions/mixpanel'
import { updateUser } from '../../functions/user'
import SpinnerIcon from '../SpinnerIcon'
import { generateToggledFunction } from '../../utils/misc'

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: '#E5E5E5',
      fontSize: '1.6rem',
      '& label.Mui-focused': {
        color: theme.palette.primary.main,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: theme.palette.primary.main,
      },
    },
  },
  companyName: {
    fontSize: '4.0rem',
    color: theme.palette.primaryCompliment.main,
  },
  subHeading: {
    color: theme.palette.primary.main,
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    padding: '20px 10px',
    paddingTop: '100px',
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: 'white',
    width: 160,
    height: 160,
    padding: '5px',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  signup: {
    margin: theme.spacing(1, 0, 1),
    borderRadius: '50px',
    fontSize: '1.6rem',
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50px',
    fontSize: '1.6rem',
    '&:hover': {
      backgroundColor: '#c18135',
      color: theme.palette.common.white,
    },
  },
  error: {
    color: theme.palette.error.main,
  },
}))

const SignInPage = ({ history }) => <SignIn history={history} />

function SignIn(props: { history: Object }) {
  const { history } = props
  const { login } = useAuth()
  const classes = useStyles()
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [error, setError] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [isInvalid, setIsInvalid] = React.useState(
    password.length < 5 || email.length < 5
  )

  const onSubmit = async event => {
    event.preventDefault()
    setLoading(true)
    try {
      const result = await login(email, password)
      if (result) {
        analytics.logEvent('successful_login')
        Mixpanel.identify(result.user.uid)
        Mixpanel.track('Successful login')

        setEmail('')
        setPassword('')
        setError(null)
        setLoading(false)
        window.location = '/'
      }
    } catch (err) {
      setError(err.message)
      setLoading(false)
    }
  }

  React.useEffect(() => {
    setIsInvalid(email.length < 5 && password.length < 5)
  }, [email, password])

  return (
    <Container component="main" maxWidth="xs" history={history}>
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h2" variant="h5" className={classes.subHeading}>
          welcome to
        </Typography>
        <Typography className={classes.companyName} component="h1" variant="h5">
          <b>Family Proud</b>
        </Typography>
        <Avatar
          className={classes.avatar}
          src={require('../../images/logo-brand-multi.png')}
        />
        {error && (
          <div className={classes.error} data-cy="sign-in-error">
            {error}
          </div>
        )}
        <form
          className={classes.form}
          noValidate
          onSubmit={generateToggledFunction(
            e => onSubmit(e),
            isInvalid || loading
          )}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={e => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={e => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isInvalid || loading}>
            Sign In
            {loading && (
              <>
                &nbsp;&nbsp;
                <SpinnerIcon />
              </>
            )}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href={routes.PASSWORD_FORGET} variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  )
}

export default withRouter(SignInPage)

export { SignIn }
