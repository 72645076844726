import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  closeIcon: {
    float: 'right',
    padding: '2px'
  }
}))

export default function Terms({ open, handleClose }) {
  const classes = useStyles()

  return (
    <Dialog open={open} onClose={handleClose} scroll="paper">
      <DialogTitle>
        Terms &amp; Conditions
        <IconButton
          edge="end"
          color="inherit"
          className={classes.closeIcon}
          aria-label="close"
          onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <p>
          Thank you for visiting the Family Proud, Inc. (“Family Proud”)
          website, and for your interest in the content and online services
          accessible on our website.
        </p>
        <p>
          <strong>
            PLEASE READ THESE TERMS OF SERVICE CAREFULLY BEFORE USING OUR
            WEBSITE.
          </strong>
        </p>
        <p>
          <strong>
            By visiting the Family Proud website (“Website”) or using any
            services that we offer through our Website, through a mobile
            application or otherwise (“Services”), you agree that you have read
            and agree to be bound as a party to these Terms of Service
            (“Agreement”). Unless you unconditionally agree to these Terms of
            Service, you have no right to use our Website or our Services.
          </strong>
        </p>
        <ol>
          <li>
            <strong>Key Definitions</strong>
            <p>
              <strong>Authorized Contractor. </strong>Business entities or
              person hired by Family Proud under legal agreements to perform
              tasks on our behalf or on behalf of our users, e.g. to provide
              Family Proud Services to our users.
            </p>
            <p>
              <strong>Individual. </strong>A natural person who is the subject
              of Personal Information.
            </p>
            <p>
              <strong>Personal Information. </strong>A data element or set of
              information that can be used to identify an Individual, either
              alone or in combination with other information. Personal
              Information may include, for example, “personally identifiable
              information” as defined in the California Online Privacy
              Protection Act of 1993 (CalOPPA).
            </p>
          </li>
          <li>
            <strong>Access to the Family Proud Website and Services</strong>
            <p>
              Our Website, domain name (i.e. www.familyproud.com), and any
              Services, other linked pages, features, or content offered at the
              time of your agreement to these Terms of Services are owned and
              operated by Family Proud. We may change, suspend or discontinue
              the Website or certain content, features (including one or more of
              our Services), or links within or from the Website at any time and
              without notice. Family Proud may also specifically limit your
              access to certain content, Services, and other features of our
              Website without notice or liability. We reserve the right, in our
              sole discretion, to amend these Terms of Services at any time by
              posting a notice on our Website, or otherwise providing you
              written notice of the amendments. You shall be responsible for
              reviewing and becoming familiar with any such amendments. By using
              our Services or other features of our Website following such
              notice, you agree that you have read and agree to be bound as a
              party to the amended Terms of Service.
            </p>
          </li>
          <li>
            <strong>Content</strong>
            <p>
              The information, images and other content of the Family Proud
              Website and/or accessible through our Services (“Family Proud
              Content”) are intended solely for the personal, non-commercial use
              of authorized users of our Services and other visitors to our
              Website, and may only be accessed and used in accordance with the
              provisions of these Terms of Service. All Family Proud Content is
              covered by copyright and other intellectual property protections
              under applicable laws. Therefore, you shall not use, copy,
              reproduce, modify, translate, publish, broadcast, transmit,
              distribute, perform, upload, display, license, sell or otherwise
              exploit for any purposes whatsoever any Family Proud Content, or
              software code, or other feature of our Website or Services, in
              whole or in part, whether owned by Family Proud or another party,
              with the exception of content that you may disclose to us in the
              course of using our Services, as described in Family Proud’s{' '}
              <a href="https://familyproud.com/privacy">Privacy Policy</a>.
            </p>
            <p>
              You hereby understand that all information and other content not
              directly owned or licensed by Family Proud that are publicly
              posted or privately transmitted through our Website are the sole
              responsibility of the person or other legal entity (e.g. user,
              Authorized Contractor) from whom such content originated, and that
              Family Proud will not be liable for any errors, omissions, lack of
              authenticity, or violations of copyright or other applicable
              intellectual property protection laws regarding such content not
              directly owned or licensed by us.
            </p>
          </li>
          <li>
            <strong>Who May Use the Family Proud Website and Services</strong>
            <p>
              Users of our Website may include unregistered guests who visit
              public-facing portions of our Website, as well as registered
              authorized users of our Services. You may need to create an
              account to use our Services. To protect your Family Proud account
              and your Personal Information, please keep your access credentials
              confidential. You must provide accurate, complete and current
              registration information in order to create an account.
            </p>
            <p>
              Our Services are intended for use by citizens and residents of the
              United States. Please read our{' '}
              <a href="https://www.familyproud.com/privacy">Privacy Policy</a>{' '}
              for additional information regarding the scope of intended users
              of our Services.
            </p>
            <p>
              You must be at least 13 years old to create an account to use our
              Services. A parent, legal guardian, or other legally authorized
              representative (e.g. person with power of attorney to make
              healthcare decisions on behalf of another Individual) may create
              an account on behalf of a minor or an Individual who lacks
              capacity to make his/her own health care decisions. By creating an
              account on behalf of an Individual, you represent that you are
              that Individual’s legally authorized representative. If you are a
              legally authorized representative, you acknowledge that Family
              Proud may ask you to provide documentation verifying that you have
              the authority to represent the Individual for whom you created an
              account. A minor between the ages of 13 and 17 may create a user
              account if his/her parent or guardian provides us with express
              written permission to do so.
            </p>
            <p>
              Any minor younger than 13 years of age (i.e. a “child”) is not
              permitted to use our Services. Family Proud does not operate a
              “website or online service directed to children”, as defined in
              the federal Children’s Online Privacy Protection Act (COPPA). We
              do not knowingly seek or collect any Personal Information directly
              from children under the age of 13. If we become aware that we have
              unknowingly collected any Personal Information directly from a
              child under the age of 13, we will take commercially reasonable
              efforts to delete such data from our system.
            </p>
          </li>
          <li>
            <strong>User Activity Guidelines and Restrictions</strong>
            <p>
              You are responsible for all your activity while using or otherwise
              in connection with our Website.
            </p>
            <p>
              You warrant, represent and agree that you will not conduct, cause
              or participate in the following activities in the course of, or as
              a result of access to and use of our Website (“Restricted
              Activities”):
            </p>
            <ul>
              <li>
                Infringement or violation of the intellectual property rights or
                other rights concerning the protection or privacy of information
                or other assets owned by or entrusted to Family Proud, it’s
                Authorized Contractors, or any other third party, including
                Family Proud Content;
              </li>
              <li>
                Uploading to or distribution via our Website or Services of any
                information or other content that may prudently be construed as
                harmful, fraudulent, deceptive, threatening, abusive, harassing,
                tortious, defamatory, vulgar, obscene, libelous, or otherwise
                objectionable;
              </li>
              <li>
                Marketing, sales, or other commercial activities without our
                express written approval;
              </li>
              <li>
                Disseminating electronic message or email spam, or operating
                other types of manual or automated messaging services without
                our express written approval;
              </li>
              <li>Impersonation of any person or legal entity;</li>
              <li>
                Compromise of information security safeguards, or exploitation
                of gaps in information security safeguards, including but not
                limited to phishing and other forms of social engineering used
                to facilitate unauthorized access to any Family Proud or
                third-party computing system;
              </li>
              <li>
                Uploading, execution, and/or dissemination of any malicious
                software (e.g. virus, trojan horse, worm);
              </li>
              <li>
                Compromising the functionality or availability of the Website,
                Services or any other Family Proud or third-party computing
                system;
              </li>
              <li>
                Decompiling, reverse engineering, or other attempt to obtain the
                source code of the Website;
              </li>
              <li>
                Any other unauthorized access to or use of our Website; or
              </li>
              <li>
                Violation of any law, whether covering Restricted Activities
                described above or not.
              </li>
            </ul>
          </li>
          <li>
            <strong>Discretion to Remove and Block Website Content</strong>
            <p>
              We reserve the right to temporarily or permanently remove content
              from our Website or Services at any time. We also reserve the
              right to temporarily or permanently block upload to, distribution
              via, or presentation on our Website or Services of any content at
              any time. We may take these actions for reasons including but not
              limited to receipt of a complaint or other notification from a
              government entity or other third party alleging, claiming or
              verifying commission of a Restricted Activity; however we do not
              need a specific reason to do so.{' '}
            </p>
          </li>
          <li>
            <strong>Disclaimer of Warranties</strong>
            <p>
              Family Proud disclaims any liability regarding any actions
              resulting from your use of our Services or other features of our
              Website. You acknowledge and agree that your use of the Website
              and Services is at your own risk. Our Services and other features
              of our Website are provided “as is” and “as available”, and we
              expressly disclaim ALL express and implied warranties of any kind
              regarding our Website and Services, including but not limited to:
            </p>
            <ul>
              <li>
                Fitness for a particular purpose or in accordance with your
                requirements or expectations;
              </li>
              <li>Implied warranties of merchantability;</li>
              <li>Lack of interruption of availability; or</li>
              <li>
                Absolute security of information stored or transmitted to, from
                or within.
              </li>
            </ul>
            <p>
              Family Proud is not a health care provider and does not provide
              medical advice. You further acknowledge and agree that any
              information or files downloaded or otherwise obtained via your use
              of our Website or Services is done at your own discretion and
              risk, and that you will be solely responsible for any damages to
              your computing device or your other personal property that results
              from acquiring such information or files.
            </p>
            <p>
              If you are a patient or healthcare consumer, you should not use
              information found on our Website in place of the advice of your
              physician or other health care professional, and should not rely
              on information on our Website as professional medical advice.
              Always seek the advice of your physician or other qualified
              healthcare provider concerning questions you have regarding a
              medical condition, and before starting, stopping or modifying any
              treatment or medication. In the case of a health emergency, seek
              immediate assistance from emergency personnel. Never delay
              obtaining medical advice or disregard medical advice because of
              something you have or have not read on our Website.
            </p>
          </li>
          <li>
            <strong>Privacy Policy</strong>
            <p>
              For information regarding Family Proud’s protection of Personal
              Information and privacy practices regarding such information,
              please review our current{' '}
              <a href="https://familyproud.com/privacy">Privacy Policy</a>,
              which is hereby incorporated by reference.
            </p>
          </li>
          <li>
            <strong>Indemnity</strong>
            <p>
              You will indemnify and hold Company, its parents, subsidiaries,
              affiliates, officers, and employees harmless (including, without
              limitation, from all damages, liabilities, settlements, costs and
              attorneys’ fees) from any claim or demand made by any third party
              due to or arising out of your access to the Family Proud Website,
              use of our Website, your violation of this Agreement, or the
              infringement by you or any third party using your account of any
              intellectual property or other right of any person or entity.
            </p>
          </li>
          <li>
            <strong>Limitation of Liability</strong>
            <p>
              We limit our liability to the fullest extent allowed by applicable
              law. With respect to this Agreement, the Family Proud Website, or
              our Services, in no event shall Family Proud be liable under any
              legal or equitable theory for any unintentional damage, any
              actual, incidental, or consequential damage, or for any loss or
              claim of any kind. Some jurisdictions do not allow us to have a
              broad limit on our liability. If you are dissatisfied with any
              portion of the terms of this Agreement, our Services or other
              features of our Website, your sole remedy is to stop using our
              Website, and if you are using any of our Services, cancel your
              subscription to our Services. Our total liability in any matter
              related to this Agreement or use of our Website is limited to the
              aggregate amount you paid us during the 12-month period preceding
              the event giving rise to the liability. If you live in one of
              those jurisdictions, some of these limitations may not apply to
              you.
            </p>
          </li>
          <li>
            <strong>Interactions with Third Parties</strong>
            <p>
              Our Website may contain links to third-party websites or services
              (“Third-Party Websites”) that are not owned or controlled by
              Family Proud. When you access Third-Party Websites, you do so at
              your own risk. We have no control over, and assume no
              responsibility for, the content, accuracy, privacy policies,
              business practices, or opinions of any third party or Third-Party
              Websites. We will not and cannot monitor, verify, censor or edit
              the content of any Third-Party Website. By using our Website, you
              expressly relieve and hold harmless Family Proud from any and all
              liability arising from your use of any Third-Party Website.
            </p>
            <p>
              Your interactions with third parties via our Website are solely
              between you and such third parties. You should make whatever
              investigation you feel necessary or appropriate before proceeding
              with any online or offline transaction with any of these third
              parties. You agree that Family Proud shall not be responsible or
              liable for any loss or damage incurred as the result of any such
              interactions. If there is a that does not involve Family Proud
              between you and another user of our Website, or between you and
              any third party, you understand and agree that we are under no
              obligation to become involved. In the event that you have a
              dispute with one or more other users or third parties, you hereby
              release Family Proud, its officers, employees, agents, and
              successors in rights from claims, demands, and damages (actual and
              consequential) of every kind or nature, known or unknown,
              suspected or unsuspected, disclosed or undisclosed, arising out of
              or in any way related to such disputes. If you are a California
              resident, you shall and hereby do waive California Civil Code
              Section 1542, which states: “A general release does not extend to
              claims which the creditor does not know or suspect to exist in his
              favor at the time of executing the release, which, if known by him
              must have materially affected his settlement with the debtor.”
            </p>
          </li>
          <li>
            <strong>
              Termination, Suspension and Limitation of User Account and Access
              Privileges.
            </strong>
            <p>
              This Agreement shall remain in full force and effect while you use
              our Services or other features of our Website. You may terminate
              your use of our Services at any time. We may also terminate or
              suspend your access to our Services and/or other features of our
              Website immediately, without prior notice or liability, if you
              breach any of the terms or conditions of this Agreement or of our{' '}
              <a href="https://familyproud.com/privacy">Privacy Policy</a>. Upon
              termination of your account, your right to access our Services
              will cease immediately. All provisions of this Agreement which, by
              their nature, should survive termination, shall survive
              termination, including, without limitation, ownership provisions,
              warranty disclaimers, and limitations of liability.
            </p>
          </li>
          <li>
            <strong>Entire Agreement</strong>
            <p>
              These Terms of Service, including all rules, guidelines, and other
              documents incorporated herein by reference, comprise the entire
              Agreement between you and Family Proud regarding your use of our
              Website, and supersede any prior express or implied agreements
              regarding your use of our Website.
            </p>
          </li>
          <li>
            <strong>Applicable Law and Arbitration</strong>
            <p>
              If you have a dispute regarding this Agreement, the Family Proud
              Website, or our Services, such dispute shall be governed by
              California law regardless of your country of origin or residence,
              or where you access our Website and Services. Any disputes shall
              be resolved by final and binding arbitration conducted in English,
              in San Diego, California, under the rules and auspices of the
              American Arbitration Association, to be held in San Diego,
              California, in English, with a written decision stating legal
              reasoning issued by the arbitrator(s) at either party's request.
              Arbitration costs and reasonable documented attorneys' costs of
              both parties shall be borne by the party that ultimately loses.
              Either party may obtain preliminary or permanent injunctive relief
              and orders to compel arbitration or enforce arbitral awards in any
              court of competent jurisdiction. Furthermore, you understand and
              agree that this Agreement waives the right to a trial by jury or
              to participate in a class action against Family Proud.
            </p>
          </li>
          <li>
            <strong>Term for Cause of Action</strong>
            <p>
              You agree that regardless of any statute or law to the contrary,
              any claim or cause of action arising out of or related to this
              Agreement, the Family Proud Website, or our Services must be filed
              within one (1) year after such claim or cause of action arose or
              be forever barred.
            </p>
          </li>
          <li>
            <strong>Notices</strong>
            <p>
              We may send notices regarding this Agreement to users of our
              Website for whom we have a physical address and/or e-mail address
              on file. We may also publish notices regarding amendments to our
              Terms of Service on our Website.
            </p>
            <p>
              Please send written notices pertaining to this Agreement by email
              to: <a href="mailto:info@familyproud.com">info@familyproud.com</a>
              , or via mail to:<br></br>
              Family Proud, Inc.<br></br>
              1041 Market st. Unit 187<br></br>
              San Diego, California 92101
            </p>
          </li>
          <li>
            <strong>Assignment</strong>
            <p>
              As a unique user of our Website, you may not, without our written
              consent, assign or transfer any of your rights and obligations
              under this Agreement. There shall be no third-party beneficiaries
              to this Agreement. We reserve the right to assign or transfer our
              rights and obligations under this Agreement.
            </p>
          </li>
          <li>
            <strong>Amendments</strong>
            <p>
              We reserve the right to amend the terms of this Agreement,
              effective upon publication of such amended Terms of Service on our
              Website, or otherwise providing you notice of the amendment. If
              you do not want to agree to changes to the Agreement, you can
              terminate the Agreement at any time per section 12 above.
            </p>
          </li>
          <li>
            <strong>Severability</strong>
            <p>
              If any portion of this Agreement is in violation of any law or is
              found to be otherwise unenforceable, the remainder of this
              Agreement shall remain in full force and effect.
            </p>
          </li>
          <li>
            <strong>Effective Date</strong>
            <p>
              This version of these Terms of Service went into effect July 1st,
              2019.
            </p>
          </li>
        </ol>
      </DialogContent>
    </Dialog>
  )
}
