// @flow
/* Sends invitation email. */
import { SIGNUP_NOTIFICATION } from '../constants/functions'

export async function sendSignupNotification(
  emailAddress: string,
  firstname: string,
  lastname: string
) {
  const body = {
    email: emailAddress.toLowerCase(),
    host: window.location.host,
    firstname,
    lastname
  }

  try {
    const response = await fetch(SIGNUP_NOTIFICATION, {
      method: 'post',
      body: JSON.stringify(body)
    })

    return response.statusText === 'OK'
  } catch (err) {
    console.log(err)
    return false
  }
}
