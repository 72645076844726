import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Paper,
  Tooltip,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { useCart } from '../../context/cart'
import CartItem from './CartItem'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  addItemsLink: {
    margin: '10px auto 20px',
    fontSize: '12px',
    color: theme.palette.primary.main,
    textTransform: 'uppercase'
  },
  bold: {
    fontWeight: 'bold'
  },
  container: {
    padding: '40px'
  },
  checkoutButton: {
    color: 'white',
    fontWeight: 'bold',
    padding: '10px 45px'
  },
  checkoutButtonContainer: {
    justifyContent: 'flex-end',

    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1)
  },
  emptyMessage: {
    color: '#ccc',
    margin: '15px 0'
  },
  header: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    }
  },
  image: {
    height: '100%',
    objectFit: 'contain',
    width: '100%'
  },
  imageContainer: {
    height: '80px',
    marginRight: '35px',
    width: '80px'
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '20px 0',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  quantityInput: {
    border: 'none',
    marginLeft: '10px',
    paddingLeft: '6px',
    width: '35px'
  },
  quantityContainer: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '14px',

    '& button': {
      background: 'white',
      border: 'none',
      borderRadius: '50px'
    },

    marginTop: '10px'
  },
  quantityPicker: {
    alignItems: 'center',
    border: '1px solid #d2d2d2',
    borderRadius: '30px',
    display: 'flex',
    width: 'fit-content'
  },
  price: {
    fontSize: '16px',
    fontWeight: 'bold',

    [theme.breakpoints.down('md')]: {
      marginTop: '15px'
    }
  },
  removeButton: {
    color: '#555',
    fontSize: '14px',
    marginLeft: '30px',

    '&:hover': {
      textDecoration: 'underline'
    }
  },
  titleSection: {
    flexGrow: 2
  },
  unitPrice: {
    display: 'block',
    fontSize: '14px',
    color: '#555'
  }
}))

function Cart({ open, closeCart }) {
  const classes = useStyles()
  const theme = useTheme()
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { cart, removeCartItem, updateCartItem } = useCart()

  const checkoutFromShopify = async () => {
    closeCart()
    window.open(cart.webUrl)
  }

  return (
    <Dialog open={open} fullScreen={fullscreen} maxWidth="md" fullWidth>
      <DialogTitle disableTypography>
        <Tooltip title="Close">
          <IconButton onClick={closeCart} className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>

      <Paper className={classes.container}>
        <Typography variant="h5" component="h3" className={classes.header}>
          Shopping Cart
        </Typography>
        <List>
          {cart.lineItems &&
            cart.lineItems.map(({ id, title, quantity, variant }) => {
              if (variant)
                return (
                  <CartItem
                    key={variant.id}
                    productTitle={title}
                    variant={variant}
                    lineItemId={id}
                    quantity={quantity}
                    classes={classes}
                    onRemove={removeCartItem}
                    onUpdate={updateCartItem}
                  />
                )
            })}
        </List>
        {(!cart.lineItems || cart.lineItems.length === 0) && (
          <Grid container direction="column" alignItems="center">
            <Typography variant="subtitle2" className={classes.emptyMessage}>
              (EMPTY)
            </Typography>
            <Link
              onClick={closeCart}
              className={classes.addItemsLink}
              to="/registry">
              Add Products
            </Link>
          </Grid>
        )}
        <Divider />
        <List>
          <ListItem className={classes.listItem}>
            <Typography className={classes.bold} variant="h6">
              Subtotal
            </Typography>
            <Typography className={classes.bold} variant="h6">
              ${cart.subtotalPrice}
            </Typography>
          </ListItem>
        </List>
        <Grid container className={classes.checkoutButtonContainer}>
          <Button
            className={classes.checkoutButton}
            variant="contained"
            color="primary"
            onClick={checkoutFromShopify}
            disabled={!cart.lineItems || cart.lineItems.length === 0}>
            Checkout
          </Button>
        </Grid>
      </Paper>
    </Dialog>
  )
}

export default Cart
