import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import Hidden from '@material-ui/core/Hidden'

import { usePage } from '../../context/page'
import {
  HomeIcon,
  CalendarIcon,
  ResourcesIcon,
  PeerToPeerIcon
} from '../../icons'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    zIndex: 100
  },
  action: {
    minWidth: 'auto',
    '& svg': {
      fill: '#4F4F4F'
    }
  },
  selected: {
    '& svg': {
      fill: theme.palette.primary.main
    }
  }
}))

export default function MobileNav(props: Object) {
  const { path } = props
  const classes = useStyles()
  const [state, dispatch] = usePage()
  const { currentPage } = state
  const updateCurrentPage = (event, newValue) => {
    dispatch({
      type: 'UPDATE_CURRENT_PAGE',
      payload: newValue === '/' ? 'home' : newValue.replace('/', '')
    })
  }

  React.useEffect(() => {
    const payload = path && path.length > 1 ? path.replace('/', '') : 'home'
    if (payload) {
      dispatch({ type: 'UPDATE_CURRENT_PAGE', payload })
    } else {
    }
  }, [path, dispatch])

  return (
    <Hidden smUp>
      <BottomNavigation
        showLabels
        value={currentPage}
        className={classes.root}
        onChange={updateCurrentPage}>
        <BottomNavigationAction
          className={classes.action}
          component={Link}
          to={'/'}
          classes={{ selected: classes.selected }}
          label="Home"
          value="home"
          icon={<HomeIcon className={classes.action} />}
        />
        <BottomNavigationAction
          className={classes.action}
          component={Link}
          to={'/resources'}
          classes={{ selected: classes.selected }}
          label="Resources"
          value="resources"
          icon={<ResourcesIcon />}
        />
        <BottomNavigationAction
          className={classes.action}
          component={Link}
          to={'/peer'}
          classes={{ selected: classes.selected }}
          label="P2P"
          value="peer-to-peer"
          icon={<PeerToPeerIcon />}
        />
        <BottomNavigationAction
          className={classes.action}
          classes={{ selected: classes.selected }}
          component={Link}
          to={'/calendar'}
          label="Calendar"
          value="calendar"
          icon={<CalendarIcon />}
        />
      </BottomNavigation>
    </Hidden>
  )
}
