// @flow
import { firestore } from 'firebase/app'

import { getUserInfo } from './user'

/**
  - user
    - conversations:
      - conversationid: string,

 - conversations:
    - id: string
    - updated: date
    - recipients: {
      - userid: string
    }
    - messages: {
      - read: [
        - userid
      - id: string
      - senderid: string
      - message: text
      - date: date
      - media: url
      ]
    }
 **/

export async function getConversations(userid: string, callback: Function) {
  const unsubscribe = await firestore()
    .collection(`users`)
    .doc(userid)
    .onSnapshot(doc => {
      if (doc.exists) {
        const conversations = doc.data().conversations
        callback(conversations)
      }
    })

  return unsubscribe
}

export async function getConversation(id: string) {
  const result = await firestore()
    .doc(`conversations/${id}`)
    .get()
  return result.data()
}

export async function subscribeToMessages(
  conversationid: string,
  callback: Function
) {
  const result = firestore()
    .doc(`conversations/${conversationid}`)
    .onSnapshot(data => {
      if (data.exists) {
        callback(data.data().messages)
      }
      callback(null)
    })

  return result
  // return res
}

/**
 *
 * @param conversationid
 * @param senderid
 * @param messageData: {message: text, media: url}
 *
 */
export async function addMessage(
  conversationid: string,
  senderid: string,
  messageData: Object
) {
  const conversationRef = firestore().doc(`conversations/${conversationid}`)

  const conversation = await conversationRef
    .get()
    .then(doc => (doc.exists ? doc.data() : null))
  const { recipients } = conversation

  const read = {}
  for (const rec of recipients) {
    read[rec] = senderid === rec
  }

  const sender = await getUserInfo(senderid, true)

  await conversationRef.update({
    updated: new Date().getTime(),
    messages: firestore.FieldValue.arrayUnion({
      senderid,
      sender: sender && `${sender.firstName} ${sender.lastName}`,
      date: new Date().getTime(),
      read,
      ...messageData
    })
  })
}

export async function removeMessage(
  conversationId,
  message
) {
  const conversationRef = firestore().doc(`conversations/${conversationId}`)

  if (!conversationRef) return

  await conversationRef.update({
    updated: new Date().getTime(),
    messages: firestore.FieldValue.arrayRemove(message)
  })
}

export async function addRecipient(
  conversationid: string,
  recipientid: string
) {
  try {
    console.log('in add')
    await firestore()
      .doc(`conversations/${conversationid}`)
      .update({
        recipients: firestore.FieldValue.arrayUnion(recipientid)
      })

    await firestore()
      .doc(`users/${recipientid}`)
      .update({
        conversations: firestore.FieldValue.arrayUnion(conversationid)
      })

    return true
  } catch (err) {
    console.log(err)
    return false
  }
}

export async function deleteRecipient(
  conversationid: string,
  recipientid: string
) {
  try {
    const recipients = await firestore()
      .collection(`conversations/${conversationid}/recipients`)
      .get()
    const updatedRecipients = recipients.filter(id => id !== recipientid)

    await firestore.doc(`conversations/${conversationid}`).update({
      recipients: updatedRecipients
    })

    const user = await firestore
      .collection(`users/${recipientid}/conversations`)
      .get()
    const updatedConversations = user.filter(id => id !== conversationid)
    await firestore.doc(`users/${recipientid}`).update({
      conversations: updatedConversations
    })

    return true
  } catch (err) {
    console.log(err)
    return false
  }
}

export async function addConversation(
  recipients: Object[],
  loggedInUser: Object = null
) {
  if (loggedInUser) {
    recipients.push(loggedInUser)
  }
  const conversation = await firestore()
    .collection('conversations')
    .add({
      recipients: recipients.map(recipient => recipient.id)
    })
    .then(docRef => docRef.id)

  recipients.map(async (recipient, idx) => {
    const others = recipients.map(({ id }) => id)
    others.splice(idx, 1)

    await addUserConversation(recipient.id, conversation)
  })

  return conversation
}

async function addUserConversation(user, conversationid) {
  return firestore()
    .doc(`users/${user}`)
    .update({
      conversations: firestore.FieldValue.arrayUnion(conversationid)
    })
}

export async function findConversation(userID: string, users: string[]) {
  const user = await firestore()
    .doc(`users/${userID}`)
    .get()

  let foundConversation = 0
  const conversations = user.data().conversations

  users.push(userID)

  if (conversations) {
    for (const conv of user.data().conversations) {
      let found = 0
      const conversation = await getConversation(conv)
      if (conversation) {
        
        conversation.recipients.forEach(recipient => {
          if (users.includes(recipient)) {
            found++
          }
        })
                
        if (conversation.recipients.length === found && found === users.length) {
          foundConversation = conv
        }
      }
    }
  }

  return foundConversation
}

export async function markMessagesRead(conversationId: string, userId: string) {
  const ref = firestore().doc(`conversations/${conversationId}`)
  const conversation = await ref.get()
  if (conversation.exists) {
    const messages = conversation.data().messages
    const readMessages = messages.map(message => {
      message.read[userId] = true
      return message
    })

    return ref.update({
      messages: readMessages
    })
  }
}
