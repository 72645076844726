// @flow
import React from 'react'

import { ReactComponent as Resources } from './Resources-Inactive.svg'
import { ReactComponent as Calendar } from './TaskShare-Inactive.svg'
import { ReactComponent as Registry } from './Registry-Inactive.svg'
import { ReactComponent as PeerToPeer } from './P2P-Inactive.svg'
import { ReactComponent as Invite } from './Invite.svg'
import { ReactComponent as Settings } from './Settings.svg'
import { ReactComponent as FAQ } from './FAQ.svg'
import { ReactComponent as Feedback } from './Edit.svg'
import { ReactComponent as Menu } from './menuIcon.svg'
import { ReactComponent as Home } from './Home-Inactive.svg'
import { ReactComponent as DirectMessage } from './DirectMessage.svg'
import { ReactComponent as Notification } from './Notification.svg'
import { ReactComponent as Cancel } from './Cross.svg'
import { ReactComponent as Save } from './Send.svg'
import { ReactComponent as Marker } from './Marker.svg'
import { ReactComponent as Friend } from './User.svg'
import { ReactComponent as New } from './NewPost.svg'
import { ReactComponent as Nothing } from './Nothing.svg'

export const ResourcesIcon = Resources
export const CalendarIcon = Calendar
export const RegistryIcon = Registry
export const PeerToPeerIcon = PeerToPeer
export const InviteIcon = Invite
export const SettingsIcon = Settings
export const FAQIcon = FAQ
export const FeedbackIcon = Feedback
export const MenuIcon = Menu
export const HomeIcon = Home
export const DirectMessageIcon = DirectMessage
export const NotificationIcon = Notification
export const CancelIcon = Cancel
export const SaveIcon = Save
export const MapMarker = Marker
export const FriendIcon = Friend
export const NewIcon = New
export const NothingIcon = Nothing
