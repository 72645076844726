import firebase from 'firebase'
import { VALIDATE_DOMAIN } from "../constants/functions"

export function isValidEmail(email) {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
}

export async function validateDomain(email) {
  // Uncomment for testing with Firebase Emulators
  // firebase.functions().useFunctionsEmulator('http://localhost:5000')
  const fn = firebase.functions().httpsCallable(VALIDATE_DOMAIN);

  try {
    const { data: isValid } = await fn({ email })

    return isValid;

  } catch (e) {
    console.log(e.message)
    return null
  }

}
