import React from 'react'
import { useAuth } from './auth'

const UserContext = React.createContext()

function reducer(state, action) {
  const { type, payload } = action
  switch (type) {
    case 'UPDATE_LOGGED_IN':
      return { ...state, loggedInUser: payload }
    case 'UPDATE_CURRENT':
      return { ...state, currentUserID: payload }
    case 'UPDATE_USER':
      return { ...state, ...payload }
    default:
      throw new Error('Invalid Action')
  }
}

function UserProvider(props) {
  const data = useAuth()
  const { user, loggedInUser } = data.state
  let currentUserID = user ? user.uid : null

  if (
    loggedInUser &&
    !loggedInUser.isPatient &&
    loggedInUser.supportedUsers.length > 0
  ) {
    currentUserID = loggedInUser.supportedUsers[0]
  }

  const [state, dispatch] = React.useReducer(reducer, {
    currentUserID,
    loggedInUser
  })

  return (
    <UserContext.Provider value={[state, dispatch]} {...props}>
      {props.children}
    </UserContext.Provider>
  )
}

function useUser() {
  const context = React.useContext(UserContext)
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`)
  }

  return context
}

export { UserProvider, useUser }
