import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  closeIcon: {
    float: 'right',
    padding: '2px'
  }
}))

export default function Privacy({ open, handleClose }) {
  const classes = useStyles()

  return (
    <Dialog open={open} onClose={handleClose} scroll="paper">
      <DialogTitle>
        Privacy Policy{' '}
        <IconButton
          edge="end"
          color="inherit"
          className={classes.closeIcon}
          aria-label="close"
          onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <p>
          Thank you for visiting the Family Proud, Inc. (“Family Proud”)
          website, and for your interest in the content and online services
          accessible on our website.
        </p>
        <p>
          <strong>
            PLEASE READ THIS PRIVACY POLICY CAREFULLY BEFORE USING OUR WEBSITE.
          </strong>
        </p>
        <p>
          <strong>
            As a user of the Family Proud website (“Website”) or any services
            offered by Family Proud through the Website, or via downloaded
            software or through a mobile application or otherwise (“Services”),
            the privacy of your information is important to us. This Privacy
            Policy explains how and why we use, store, and disclose information
            obtained or transmitted via our Website, or through our Services. By
            using our Website or Services, you hereby acknowledge that you
            understand and accept the policy provisions and privacy practices
            stated in this Privacy Policy, and you consent to Family Proud’s
            collection, use, storage and disclosure of information pertaining to
            you, and other information you are legally authorized to disclose to
            us, as described in this Privacy Policy.
          </strong>
        </p>
        <ol>
          <li>
            <strong>Key Definitions</strong>
            <p>
              <strong>Aggregate Information. </strong>A set of information
              regarding multiple Individuals aggregated/combined in a manner
              that does not reasonably allow a specific Individual to be
              identified.
            </p>
            <p>
              <strong>Customer Service Information. </strong>A set of
              information collected from you and/or created by Family Proud on
              your behalf, that we use, for example, to document and respond to
              your inquiry or concern, and undertake actions related to your
              inquiry or concern.
            </p>
            <p>
              <strong>Data Subject. </strong>The Individual who is the subject
              of Personal Information or De-Identified Information.
            </p>
            <p>
              <strong>De-Identified Information. </strong>A set of information
              about an Individual from which key data elements have been removed
              (e.g. name, date of birth, address, phone number) to such a degree
              that the information can reasonably no longer be used to identify
              the Individual.
            </p>
            <p>
              <strong>Individual. </strong>A natural person who is the subject
              of Personal Information.
            </p>
            <p>
              <strong>Personal Information. </strong>A data element or set of
              information that can be used to identify an Individual, either
              alone or in combination with other information. Personal
              Information may include, for example, “personally identifiable
              information” as defined in the California Online Privacy
              Protection Act of 1993 (CalOPPA). Family Proud collects the
              following types of Personal Information:{' '}
            </p>
            <p>
              <strong>User Registration Information. </strong>Information you
              provide about yourself, and/or on behalf of another individual who
              you represent, when registering for and/or purchasing our
              Services.
            </p>
            <p>
              <strong>User Health Information. </strong>Health-related
              information about yourself, and/or on behalf of another individual
              who you represent, that you disclose to Family Proud.
            </p>
            <p>
              <strong>User Digital Media and Content. </strong>Any digital
              media/files and digital content, including hypertext links to such
              media/files and content, provided to Family Proud by users, e.g.
              photographs, videos, messages, other text information not already
              described in this section of the Privacy Policy.
            </p>
            <p>
              <strong>User Web Behavior Information. </strong>Information
              regarding how you use our Services (e.g. IP addresses, browser
              types, domains, page views) collected through log files, cookies,
              and other software services, for internal use by Family Proud.
            </p>
          </li>
          <li>
            <strong>
              Overview of Privacy Principles and Uses of Information
            </strong>
            <p>
              <strong>Core Privacy Principles. </strong>Family Proud obtains,
              uses, stores, and discloses the types of information defined above
              to make our Services available to our users; and for analysis,
              quality control, and improvement of our Services. This information
              also facilitates our compliance with applicable laws (e.g. for
              regulatory compliance and law enforcement purposes).
            </p>
            <p>
              We facilitate the lawful use of this information for the benefit
              of those users undergoing health care, participating in health
              care, and/or supporting those persons receiving care; while
              providing reasonable information security safeguards for this
              information, such as user authentication and encryption, in
              accordance with applicable laws and standards.
            </p>
            <p>
              We only use and share Personal Information as outlined in this
              Privacy Policy.
            </p>
            <p>
              <strong>Authorized Contractors. </strong>We may disclose
              information to other business entities and persons we hire under
              legal agreements (“Authorized Contractors”) to perform tasks on
              our behalf or on behalf of our users, e.g. to provide Services to
              you. In some cases, users may be able to directly disclose
              information to Authorized Contractors via our Website. The
              contracts we have executed with Authorized Contractors specify
              their permitted uses of Personal Information entrusted to them.
            </p>
            <p>
              <strong>Sale, Lease, Rental of Personal Information. </strong>We
              will not sell, lease, or rent Personal Information to any
              third-party, unless we obtain valid consent/authorization as
              required under applicable laws.
            </p>
            <p>
              <strong>Information for Legal Purposes. </strong>We reserve the
              right to access, use, store, and disclose information that we
              reasonably believe are necessary to: comply with applicable
              regulations, court orders or subpoenas; enforce our Terms of Use;
              enforce legal agreements; or protect the rights, property, or
              safety of Family Proud, the members of our workforce, our users,
              our Authorized Contractors, or other persons and legal entities to
              whom we have a legal duty.{' '}
            </p>
            <p>
              <strong>Third-Party Websites. </strong>Authorized Contractors and
              other third parties are responsible for their respective privacy
              policies and privacy practices. If a user accesses or is
              redirected to a third-website via the Family Proud Website or
              Services, it is the user’s responsibility to review the privacy
              policy posted on the third-party website.
            </p>
          </li>
          <li>
            <strong>Information We Collect, Use, Store and Disclose</strong>
            <ol>
              <li>
                <strong>Information Provided to Us by Users</strong>
                <p>
                  <strong>De-Identified Information. </strong>A user may share
                  De-Identified Information with Family Proud, e.g. information
                  submitted via a survey, or we or our Authorized Contractors
                  may create sets of De-Identified Information derived from
                  Personal Information for purposes described in this Privacy
                  Policy.
                </p>
                <p>
                  <strong>Personal Information. </strong>We gather Personal
                  Information from our users, as defined above and more fully
                  explained below, to: personalize and fulfill users’ requests
                  for our Services, improve our Services, provide customer
                  service and otherwise contact Data Subjects and other users,
                  analyze how users utilize our Website and Services, and as
                  otherwise conduct activities disclosed in this Privacy Policy.
                  Such activities may involve disclosing certain Personal
                  Information with third parties, as described in this Privacy
                  Policy.
                </p>
                <p>
                  <strong>Registration Information. </strong>Registration
                  Information facilitate your access to Family Proud Services,
                  and are utilized to enforce information security safeguards
                  such as verifying your identity as a user.
                </p>
                <p>
                  <strong>
                    User-Reported Information, including User Health
                    Information.{' '}
                  </strong>
                  If you disclose information about another Individual to us,
                  you must ensure that you have the appropriate legal authority
                  to represent that Data Subject and disclose his/her
                  information.
                </p>
                <p>
                  <strong>User Digital Media and Content. </strong>User Digital
                  Media and Content primarily enhances the user experience for
                  persons utilizing our Services on your behalf, or on behalf of
                  a Data Subject for whom you have provided us information as
                  his/her legally authorized representative.
                </p>
                <p>
                  <strong>Customer Service Information. </strong>Examples of
                  Customer Service Information include technical support
                  requests, questions or problems regarding utilization of our
                  Services, questions or concerns regarding compliance with this
                  Privacy Policy. Please review the Other Privacy Practices and
                  Individual Rights section of this Privacy Policy for further
                  details regarding important uses of Customer Service
                  Information.
                </p>
              </li>
              <li>
                <strong>
                  Other Information We Automatically Collect and Compile
                </strong>
                <p>
                  <strong>User Web Behavior Information. </strong>We may use
                  cookies and other software mechanisms that track or store user
                  behavior to facilitate and enhance your use of our Website.
                  You may be able to change your web browser preferences to
                  implement “do not track” signals and otherwise prevent or
                  limit your computing or mobile device’s acceptance of cookies
                  and other mechanisms, but this may prevent you from fully
                  utilizing features of our Website. This US federal government
                  webpage provides an overview of online tracking and how to
                  configure your browser do disable tracking:{' '}
                  <a href="https://www.consumer.ftc.gov/articles/0042-online-tracking">
                    https://www.consumer.ftc.gov/articles/0042-online-tracking
                  </a>
                  .
                </p>
                <p>
                  If you click on a link to a third-party website or otherwise
                  utilize services provided by third parties accessible via our
                  Website, such third party may also transmit cookies to you, or
                  otherwise implement user-tracking tools. This Privacy Policy
                  does not cover the use of cookies or other user tracking tools
                  implemented by any third parties, since we do not manage or
                  control utilization of such tools by the third parties. If you
                  have any questions or concerns regarding use of tracking tools
                  or other privacy-related practices by a third party, you
                  should directly contact the third party.
                </p>
                <p>
                  <strong>Aggregate Information. </strong>Aggregate Information
                  pertains to a group of Individuals, vs. any unique Individual,
                  e.g. the numbers and frequency of visitors to our Website.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <strong>
              Disclaimers Regarding Applicability of Certain Regulations and
              Privacy Frameworks
            </strong>
            <p>
              <strong>
                US Children’s Online Privacy Protection Act (COPPA).{' '}
              </strong>
              Family Proud Services and other online means for collecting
              information used by us are not designed for, intended to attract,
              nor directed toward any individual younger than 13 years of age
              (i.e. a “child”) as direct users of our Services. Family Proud
              does not operate a “website or online service directed to
              children”, as defined in COPPA, we do not intend to receive
              Personal Information directly from children. However, a person
              with legal authority to represent, and act on behalf of a child,
              e.g. parent or guardian, may share information related to the
              child with us. The legally authorized representative of the child
              attests that the representative has legal authority to share the
              child’s data with us, and assumes full responsibility under
              applicable laws for the information. HLI reserves the right to ask
              for proof of legal authority to represent a child.{' '}
            </p>
            <p>
              <strong>Privacy Shield. </strong>Family Proud currently intends
              its Services to be used by citizens and residents of the United
              States (US). Family Proud does not currently participate in the
              EU-US Privacy Shield framework, nor the Swiss-US Privacy Shield
              framework. Therefore, Family Proud Services requiring users to
              share Personal Information with us are not intended for use by
              individuals uploading data from the European Union (EU) or
              Switzerland. In the event Family Proud participates in the Privacy
              Shield frameworks in the future, we will update this privacy
              policy accordingly.{' '}
            </p>
            <p>
              <strong>EU General Data Protection Regulation (GDPR). </strong>
              Family Proud currently intends its Services to be used by citizens
              and residents of the United States. We do not market or otherwise
              target our Services to citizens or residents of the EU or European
              Economic Area (EEA). Any EU or EEA citizen or resident who wishes
              to utilize Family Proud Services is hereby cautioned that the
              information privacy and security regulations of the US, with which
              our Services are designed to be in compliance, differ from those
              of the EU and EEA. While there are a number of information privacy
              and security safeguards required under applicable US regulations
              that are similar to those under the GDPR, compliance with US
              regulations does not ensure or equate to compliance with the GDPR.
              Therefore, any EU or EEA citizen or resident who shares Personal
              Information (which may be construed as “Personal Data” as defined
              in the GDPR) acknowledges and understands that Family Proud
              currently makes no representation that we comply with the GDPR’s
              data protection requirements, nor have we implemented all of the
              GDPR’s specified data safeguards and privacy practices.
            </p>
          </li>
          <li>
            <strong>Other Privacy Practices and Individual Rights</strong>
            <p>
              <strong>
                Requests for Access to and Amendment of Personal Information.{' '}
              </strong>
              In accordance with applicable laws, you may request to review,
              receive a copy of, or amend Personal Information we store about
              you.
            </p>
            <p>
              <strong>
                Notification of Disclosure of Personal Information.{' '}
              </strong>
              If required by and in accordance with applicable laws, you will be
              notified when your Personal Information is disclosed to third
              parties.
            </p>
            <p>
              <strong>
                Requests to Restrict Disclosure of Personal Information.{' '}
              </strong>
              In accordance with applicable laws, under certain circumstances
              you may request that we restrict the disclosure of Your Personal
              Information to third parties.
            </p>
            <p>
              <strong>
                Requests for Lists of Disclosures of Personal Information to
                Third Parties.{' '}
              </strong>
              If required by and in accordance with applicable laws, you may
              request a written list of disclosures of your Personal Information
              to certain third parties.
            </p>
            <p>
              California residents, under the “Shine the Light” law (California
              Civil Code Sections 1798.83-1798.84), may request a written list
              of the categories of their Personal Information we share with
              third parties for marketing purposes, along with the contact
              information for such third parties.
            </p>
            <p>
              <strong>Incident/Breach Notification. </strong>In the event you
              discover or suspect that a data breach or other incident
              compromising Personal Information entrusted to Family Proud and/or
              our Authorized Contractors has occurred, please immediately notify
              us using the email address provided below, and include as much
              information about the incident as possible.
            </p>
            <p>
              <strong>
                Contact Information for Requests and Notifications.{' '}
              </strong>
              Please send written requests and notifications pertaining to the
              privacy practices and individual rights outlined above to{' '}
              <a href="mailto:info@familyproud.com">info@familyproud.com</a>.
            </p>
          </li>
          <li>
            <strong>Privacy Policy Amendments</strong>
            <p>
              Please note that information you disclose to Family Proud are
              subject to the version of this Privacy Policy in effect at that
              time we receive such information. The Privacy Policy is subject to
              amendment in the future, e.g. due to changes in applicable laws or
              changes in the Services we offer users. In the event we amend this
              Privacy Policy, we will notify users via an announcement on our
              Website.
            </p>
          </li>
          <li>
            <strong>Questions or Concerns</strong>
            <p>
              If you have any questions or concerns regarding this Privacy
              Policy or Family Proud’s privacy practices, please contact us at{' '}
              <a href="mailto:info@familyproud.com">info@familyproud.com</a>.
            </p>
          </li>
          <li>
            <strong>Effective Date</strong>
            <p>
              This version of the Privacy Policy went into effect July 1st,
              2019.
            </p>
          </li>
        </ol>
      </DialogContent>
    </Dialog>
  )
}
