/*

Retrieves the current user's location. Returns a JSON object with location details.
In order to call this function in another file, add the following import statement:

import { getLocation } from '../../utils';

(assuming you call this function in a subdirectory of 'components' directory)

---

Since this is an asynchronous function, we need to make a promise so that it waits for the response.
When calling this function, you need to wrap this in another async/await function inside the file you're using it.

If you place it in a component, you can do the following:

async getUserLocation() {
  let result = await getLocation();
  return result;
}

*/
import { GET_LOCATION } from '../constants/functions'

export async function getLocation() {
  const response = await fetch(GET_LOCATION)
  const data = await response.json()
  console.log(data)
  return data
}
