import React, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom'
import { ThemeProvider } from '@material-ui/styles'
import Intercom from 'react-intercom'

import { useUser } from '../../context/user'
import { theme } from '../../theme'
import { Lazy } from '../../utils/lazy'
import SignInPage from '../SignIn'
import SignUpPage from '../SignUp'
import PasswordForget from '../PasswordForget'
import PasswordReset from '../PasswordReset'
import NotFoundPage from '../NotFound'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import * as routes from '../../constants/routes'
import Layout from '../Layout'

const HomePage = Lazy('Home')
const Resources = Lazy('Resources')
const Peer = Lazy('Peer')
const Calendar = Lazy('Calendar')
const Registry = Lazy('Registry')
const Messaging = Lazy('Messaging')
const Invite = Lazy('Invite')
const Settings = Lazy('Settings')
const AdminPage = Lazy('Admin')

const AuthenticatedApp = withRouter(({ match: { path }, location }) => {
  const [state] = useUser()
  const { loggedInUser } = state
  const matches = useMediaQuery(theme.breakpoints.up('sm'))

  useEffect(() => {
    if (!matches && window.intercomSettings) {
      window.intercomSettings.hide_default_launcher = true
    }
  })

  const user = {
    user_id: loggedInUser.id,
    email: loggedInUser.email,
    name: `${loggedInUser.firstName} ${loggedInUser.lastName}`
  }

  return (
    <>
      <Intercom appID={process.env.REACT_APP_INTERCOM_ID} {...user} />
      <Switch>
        <Route
          path={routes.MESSAGING}
          component={({ match: { path }, location }) => (
            <Layout path={path} location={location}>
              <Messaging path={path} location={location} />
            </Layout>
          )}
        />
        <Route>
          <Layout path={path} location={location}>
            <Switch>
              <Route
                exact
                path={routes.HOME}
                component={generateLayout(HomePage)}
              />
              <Route
                exact
                path={routes.RESOURCES}
                component={generateLayout(Resources)}
              />
              <Route
                exact
                path={routes.PEER}
                component={generateLayout(Peer)}
              />
              <Route
                exact
                path={routes.CALENDAR}
                component={generateLayout(Calendar)}
              />
              <Route
                exact
                path={routes.REGISTRY}
                component={generateLayout(Registry)}
              />
              <Route
                exact
                path={routes.INVITE}
                component={generateLayout(Invite)}
              />
              <Route
                exact
                path={routes.SETTINGS}
                component={generateLayout(Settings)}
              />
              <Route
                exact
                path={routes.ADMIN}
                component={generateLayout(AdminPage)}
              />
              <Route path={routes.SIGN_UP} render={() => <Redirect to="/" />} />
              <Route path="*" component={generateLayout(NotFoundPage)} />
            </Switch>
          </Layout>
        </Route>
      </Switch>
    </>
  )
})

const generateLayout = Component => ({ match: { path }, location }) => (
  <Component path={path} location={location} />
)

function UnauthenticatedApp() {
  return (
    <Switch>
      <Route path="/signup" component={SignUpPage} />
      <Route path="/forgot" component={PasswordForget} />
      <Route path="/change" component={PasswordReset} />
      <Route component={SignInPage} />
    </Switch>
  )
}

function App() {
  const [state] = useUser()
  const { currentUserID } = state

  return (
    <Router>
      <ThemeProvider theme={theme}>
        {currentUserID ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      </ThemeProvider>
    </Router>
  )
}

export default App
