import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

const usersAdapter = createEntityAdapter();

const initialState = {
  usersIndex: usersAdapter.getInitialState()
}

const users = createSlice({
  name: 'users',
  initialState,
  reducers: {
    upsertUsers(state, action) {
      const users = action.payload

      usersAdapter.upsertMany(state.usersIndex, users.map(u => ({ 
        ...u, 
        _lastUpdated: Date.now() 
      })))
    },

  }
})

export const {
  upsertUsers,
} = users.actions

export default users.reducer