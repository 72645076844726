import { firestore } from 'firebase/app'
import { analytics } from '../firebase'

import { SUPPORT, FAMILY, PEER } from '../constants/groups'
import { handleBlockedUsersFilter } from './blockUsers'
import { DELETE_USER } from '../constants/functions'
import { store } from '../store'
import { upsertUsers } from '../store/slices/users'

export async function getUserInfo(userId, invalidateCache = false) {
  const storedUser = store.getState().users.usersIndex.entities[userId] || {}

  // Check if the requested user is already stored
  if (storedUser._lastUpdated && !invalidateCache) {
    // If the requester user was stored as null, return null
    if (!storedUser.email) {
      return null
    }

    // Check if the user's record is outdated
    if (Date.now() - storedUser._lastUpdated < 5 * 60 * 1000) {
      return { ...storedUser }
    }
  }

  // Retrieve the user from firestore
  const user = await firestore()
    .collection(`users`)
    .doc(userId)
    .get()
    .then(doc => {
      if (doc.exists) {
        const user = doc.data()

        return user
      }

      return null
    })

  // Store the retrieved user in redux
  store.dispatch(upsertUsers([user ? user : { id: userId }]))

  return { ...user }
}

export const getAllUserInfo = async () => {
  let allUsers = []
  const querySnapshot = await firestore()
    .collection(`users`)
    .get()
  querySnapshot.forEach(function(doc) {
    let user = doc.data()
    allUsers.push(user)
  })

  store.dispatch(upsertUsers(allUsers))

  return allUsers
}

export const deleteUser = async userId => {
  try {
    await deleteUserFromCollection(`users`, userId)
  } catch (error) {
    console.error('Error removing user: ', error)
  }
}

export const deleteUserFromFirebase = async id => {
  const body = {
    id: id
  }
  try {
    const response = await fetch(DELETE_USER, {
      method: 'post',
      body: JSON.stringify(body),
      mode: 'no-cors'
    })
    await deleteUser(id)
    return response.status === 200
  } catch (err) {
    console.log('err', err)
    return false
  }
}

export const deleteUserFromCollection = async (collection, userId) => {
  try {
    await firestore()
      .collection(collection)
      .doc(userId)
      .delete()
  } catch (error) {
    console.error('Error removing user: ', error)
  }
}

export const deleteUserFromCalendar = async userId => {
  try {
    await firestore()
      .collection('calendars')
      .get()
      .then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          if (userId === doc.data().userId) {
            doc.ref.delete()
          }
        })
      })
  } catch (error) {
    console.error('Error removing user from calendar: ', error)
  }
}

export const deleteUserFromMessages = async userId => {
  try {
    await firestore()
      .collection('conversations')
      .get()
      .then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          if (doc.data().recipients.includes(userId)) {
            doc.ref.delete()
          }
        })
      })
  } catch (error) {
    console.error('Error removing user from messages: ', error)
  }
}

export function createUser(
  id,
  firstName,
  lastName,
  email,
  lat,
  lng,
  isPatient,
  supporterCode,
  group,
  organizationId
) {
  const userData = {
    firstName,
    lastName,
    email,
    id,
    lat,
    lng,
    isPatient,
    isPeer: false,
    isFirstLogin: true,
    supportNetwork: [],
    peers: [],
    family: [],
    supportedUsers: [],
    homePage: {
      profileChecked: false,
      registryChecked: false,
      inviteChecked: false,
      calendarChecked: false
    },
    emailVerified: false,
    lastSignInTime: new Date().getTime(),
    group,
    invitedUser: false,
    organizationId
  }

  if (supporterCode) {
    userData.supportedUsers.push(supporterCode)
    userData.invitedUser = true
  }

  return firestore()
    .collection(`users`)
    .doc(id)
    .set(userData)
}

export async function getSupportNetwork(user, withSupported = false) {
  const refreshedUser = await getUserInfo(user.id, true)
  const supportGroup = await getGroupMembers(refreshedUser.supportNetwork)
  const peerGroup = await getGroupMembers(refreshedUser.peers)
  const familyGroup = await getGroupMembers(refreshedUser.family)

  let supported = []

  if (withSupported) {
    supported = await getGroupMembers(user.supportedUsers)
  }

  return {
    support: supportGroup.filter(handleBlockedUsersFilter(user)),
    peer: peerGroup.filter(handleBlockedUsersFilter(user)),
    family: familyGroup.filter(handleBlockedUsersFilter(user)),
    supported: supported.filter(handleBlockedUsersFilter(user))
  }
}

export async function getGroupMembers(userIds) {
  let members = []
  if (userIds) {
    members = await Promise.all(userIds.map(userId => getUserInfo(userId)))
  }
  return members
}

export async function addGroupMember(group, userId, memberID) {
  let network = ''
  switch (group) {
    case 'support':
      network = SUPPORT
      break
    case 'peer':
      network = PEER
      break
    case 'family':
      network = FAMILY
      break
    default:
      network = SUPPORT
  }

  const alreadyConnected = await isAlreadyConnected(memberID, userId)

  if (!alreadyConnected) {
    return firestore()
      .collection('users')
      .doc(userId)
      .update({
        [network]: firestore.FieldValue.arrayUnion(memberID)
      })
  }
}

export const isAlreadyConnected = async (addID: string, userId: string) => {
  const user = await getUserInfo(userId, true)
  const inSupport = user.supportNetwork.filter(id => id === addID)
  const inPeer = user.peers.filter(id => id === addID)
  const inFamily = user.family.filter(id => id === addID)

  return inSupport.length > 0 || inPeer.length > 0 || inFamily.length > 0
}

export const updateUser = (id: string, data: Object, isOrganizationUser) => {
  return new Promise((resolve, reject) => {
    const ref = firestore().doc(`users/${id}`)
    if (!ref) {
      reject()
      return
    }

    let modifiedData = {
      ...data
    }

    // Enable all privacy settings if isPeer is toggled

    if (typeof modifiedData.isPeer === 'boolean') {
      const isEnabled = modifiedData.isPeer

      modifiedData = {
        ...modifiedData,
        p2pVisibility: {
          about: isEnabled,
          help: isEnabled,
          lookingFor: isEnabled,
          hardship: isEnabled
        }
      }
      if (isOrganizationUser && !isEnabled) {
        modifiedData.optIn = false
      }
    }

    ref
      .update(modifiedData)
      .then(_ => {
        analytics.logEvent('user_updated', modifiedData)
        resolve(modifiedData)
      })
      .catch(reason => {
        reject(reason)
      })
  })
}

export const addInvitation = async (
  userId: string,
  inviter: string,
  supportGroup: string
) => {
  return firestore()
    .doc(`users/${userId}`)
    .update({
      invitations: firestore.FieldValue.arrayUnion({
        id: inviter,
        group: supportGroup
      })
    })
}

export const acceptUserInvitation = async (
  currentUserId: string,
  userId: string,
  group: string
) => {
  try {
    await addGroupMember(group, userId, currentUserId)
    const user = await firestore()
      .doc(`users/${currentUserId}`)
      .get()
    const invitations = user.data().invitations
    const updatedInvitations = invitations.filter(
      invite => invite.id !== userId
    )
    await firestore()
      .doc(`users/${currentUserId}`)
      .update({
        supportedUsers: firestore.FieldValue.arrayUnion(userId),
        invitations: updatedInvitations
      })
  } catch (err) {
    console.error(err)
    return false
  }

  return true
}

export const denyUserInvitation = async (
  currentUserId: string,
  userId: string
) => {
  try {
    const user = await firestore()
      .doc(`users/${currentUserId}`)
      .get()
    const invitations = user.data().invitations
    const updatedInvitations = invitations.filter(
      invite => invite.id !== userId
    )

    await firestore()
      .doc(`users/${currentUserId}`)
      .update({
        invitations: updatedInvitations
      })
  } catch (err) {
    console.error(err)
    return false
  }

  return true
}
