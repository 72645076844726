import { firestore } from 'firebase/app'
import { getAllUserInfo, getGroupMembers } from './user'
import { getConversation } from './messaging'

// Sorting for blocked users
export const handleBlockedUsersFilter = me => user => {
  if (!me || !user) {
    return false
  }

  const myId = me.id
  const theirId = user.id
  const myBlockedUsers = me.blockedUsers || []
  const theirBlockedUsers = user.blockedUsers || []

  return !theirBlockedUsers.includes(myId) && !myBlockedUsers.includes(theirId)
}

// Retrieve only users the current user has not blocked, and has not been blocked by
export const getUnblockedUsers = async me => {
  const allUsers = await getAllUserInfo()

  return allUsers.filter(handleBlockedUsersFilter(me))
}

// Retrieve only users the current user has not blocked, and has not been blocked by
export const getBlockedUsers = async me =>
  getGroupMembers(me.blockedUsers || [])

export const getSearchableUsers = async me => {
  // Retrieve all users
  const allUsers = await getAllUserInfo()

  // Retrieve user conversations
  const conversationIds = me.conversations || []

  const conversations = await Promise.all(
    conversationIds.map(id => getConversation(id))
  )

  // Retrieve all recipient IDs from existing conversations
  const recipientIds = conversations
    .filter(c => c)
    .map(c => c.recipients)
    .flat()

  const supportNetworkIds = [
    me.supportNetwork || [],
    me.peers || [],
    me.family || [],
    me.blockedUsers || [],
  ].flat()

  const uniqueIds = [...new Set(recipientIds.concat(supportNetworkIds))].filter(
    i => i !== me.id
  )

  // Filter users, selecting those in P2P, in support network, or in an active conversation
  return allUsers.filter(u => uniqueIds.includes(u.id))
}

// Remove a blocked user
export const removeBlockedUser = async (userId, blockedId) => {
  const userRef = firestore().doc(`users/${userId}`)

  if (!userRef) return

  await userRef.update({
    blockedUsers: firestore.FieldValue.arrayRemove(blockedId),
  })
}

// Add a blocked user
export const addBlockedUser = async (userId, blockedId) => {
  const userRef = firestore().doc(`users/${userId}`)

  if (!userRef) return

  await userRef.update({
    blockedUsers: firestore.FieldValue.arrayUnion(blockedId),
  })
}
