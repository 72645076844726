import React from 'react'
import Shopify from 'shopify-buy'

const shopifyClient = Shopify.buildClient({
  storefrontAccessToken: process.env.REACT_APP_SHOPIFY_TOKEN,
  domain: 'family-proud-inc.myshopify.com'
})

const ShopifyContext = React.createContext(shopifyClient)

function ShopifyProvider(props) {
  return (
    <ShopifyContext.Provider value={shopifyClient}>
      {props.children}
    </ShopifyContext.Provider>
  )
}

function useShopify() {
  const context = React.useContext(ShopifyContext)
  if (context === undefined) {
    throw new Error(`useShopify must be used within a ShopifyProvider`)
  }

  return context
}

export { ShopifyProvider, useShopify, shopifyClient }
