import React, { useEffect, useState, useContext, createContext } from 'react'
import firebase from 'firebase'
import { Mixpanel } from '../functions/mixpanel'
import { shopifyClient as shopify } from './shopify'
import { useAuth } from './auth'
const CartContext = createContext()

function CartProvider({ children }) {
  const { state } = useAuth()
  const { loggedInUser } = state
  const [cart, setCart] = useState({ lineItems: [] })
  const loggedInUserId = loggedInUser && loggedInUser.id
  useEffect(() => {
    if (loggedInUserId) {
      getCart()
      return () => getCart()
    }
  }, [loggedInUserId])
  async function getCart() {
    if (!loggedInUserId) {
      return
    }
    return firebase
      .firestore()
      .collection('cart')
      .doc(loggedInUserId)
      .onSnapshot(doc => {
        if (doc.exists) {
          const { checkoutId } = doc.data()
          shopify.checkout.fetch(checkoutId).then(cart => {
            if (cart) {
              setCart(cart)
            } else {
              createCart().then(setCart)
            }
          })
        } else {
          createCart().then(setCart)
        }
      })
  }
  async function createCart() {
    const checkout = await shopify.checkout.create({
      email: loggedInUser.email,
      customAttributes: [{ key: 'fpUserId', value: loggedInUserId }]
    })
    firebase
      .firestore()
      .collection('cart')
      .doc(loggedInUserId)
      .set({ checkoutId: checkout.id })
    return checkout
  }
  function addItemToCart(variantId) {
    shopify.checkout
      .addLineItems(cart.id, [{ variantId, quantity: 1 }])
      .then(cart => {
        setCart(cart)

        Mixpanel.identify(loggedInUserId)
        Mixpanel.track('Item added to Cart')
      })
  }
  function updateCartItem(productData) {
    shopify.checkout.updateLineItems(cart.id, [productData]).then(setCart)
  }
  function removeCartItem(variantId) {
    shopify.checkout.removeLineItems(cart.id, [variantId]).then(setCart)
  }
  const providerValue = {
    cart,
    addItemToCart,
    removeCartItem,
    updateCartItem
  }
  return (
    <CartContext.Provider value={providerValue}>
      {children}
    </CartContext.Provider>
  )
}
function useCart() {
  const context = useContext(CartContext)
  if (context === undefined) {
    throw new Error(`useCart must be used within a CartProvider`)
  }
  return context
}
export { CartProvider, useCart }
