import React from 'react'
import ReactDOM from 'react-dom'
import AppProvider from './components/App/AppProvider'
import App from './components/App'
import ReactGA from 'react-ga'
import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'
import { ShopifyProvider } from './context/shopify'
import { CartProvider } from './context/cart'
import { RegistryProvider } from './context/registry'
import { MessagingProvider } from './context/messaging'

function initializeGA() {
  ReactGA.initialize('UA-146685941-1')
  ReactGA.pageview(window.location.pathname + window.location.search)
}

initializeGA()

const bugsnagClient = bugsnag({
  apiKey: 'b3284b9bb3843d7d8753aff93cbb328b',
  releaseStage: process.env.NODE_ENV
})
bugsnagClient.use(bugsnagReact, React)

const ErrorBoundary = bugsnagClient.getPlugin('react')
ReactDOM.render(
  <ErrorBoundary>
    <AppProvider>
      <ShopifyProvider>
        <CartProvider>
          <RegistryProvider>
            <MessagingProvider>
              <App />
            </MessagingProvider>
          </RegistryProvider>
        </CartProvider>
      </ShopifyProvider>
    </AppProvider>
  </ErrorBoundary>,
  document.getElementById('root')
)
