import React from 'react'
import Badge from '@material-ui/core/Badge'
import Avatar from '@material-ui/core/Avatar'
import FamilyIconWhite from '../../images/logo-brand-white.png'
import { makeStyles, withStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  orgContainer: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  icon: {
    width: '45px',
    height: '45px',
    padding: '5px'
  }
}))

const SmallAvatar = withStyles(theme => ({
  root: {
    top: 0,
    right: 0,
    width: 26,
    height: 26,
    border: `1px solid  ${theme.palette.background.paper}`
  }
}))(Avatar)

function UserOrgLogo({ organization, loggedInUser }) {
  const classes = useStyles()
  return (
    <div className={classes.orgContainer}>
      {organization !== null ? (
        <Badge
          overlap="circle"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          badgeContent={<SmallAvatar src={organization.image} />}>
          <Avatar
            className={loggedInUser.img ? '' : classes.icon}
            src={loggedInUser.img ? loggedInUser.img : FamilyIconWhite}
          />
        </Badge>
      ) : (
        <Avatar
          className={loggedInUser.img ? '' : classes.icon}
          src={loggedInUser.img || FamilyIconWhite}
        />
      )}
    </div>
  )
}

export default UserOrgLogo
