// @flow
import React, { useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Avatar from '@material-ui/core/Avatar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Badge from '@material-ui/core/Badge'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { Chip, Menu, MenuItem } from '@material-ui/core'
import { KeyboardArrowDown } from '@material-ui/icons'
import { firestore } from 'firebase/app'
import Tour from 'reactour'
import styled from 'styled-components'
import { ShoppingCart } from '@material-ui/icons'
// import SettingsDialog from '../SettingsDialog'
import Cart from '../Cart'
import { useUser } from '../../context/user'
// import SignOutButton from '../SignOut'
import { Mixpanel } from '../../functions/mixpanel'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import UserOrgLogo from '../UserOrgLogo'
import {
  CalendarIcon,
  ResourcesIcon,
  PeerToPeerIcon,
  InviteIcon,
  SettingsIcon,
  RegistryIcon,
  HomeIcon,
  MenuIcon,
  DirectMessageIcon,
  NotificationIcon
} from '../../icons'
import FamilyIconMulti from '../../images/logo-brand-multi.png'
import FamilyIconWhite from '../../images/logo-brand-white.png'
import { useStyles } from './styles'
import {
  updateUser,
  getUserInfo,
  acceptUserInvitation,
  denyUserInvitation
} from '../../functions/user'
import { usePage } from '../../context/page'
import { useCart } from '../../context/cart'
import * as routes from '../../constants/routes'
import { DELETED_USER } from '../../constants/messaging'
import { getOrganization } from '../../functions/organizationUtils'
import Box from '@material-ui/core/Box'

//overriding :before because it was giving a weirdly shaped blue border
const StyledTour = styled(Tour)`
  .reactour__dot:before {
    content: none;
  }
`
function ListItemLink(props: Object) {
  return <ListItem button {...props} />
}

ListItemLink.defaultProps = {
  component: Link
}

function PrimaryHeader(props: Object) {
  const {
    container,
    children,
    location: { pathname: path }
  } = props
  const theme = useTheme()
  const classes = useStyles()
  const [, pageDispatch] = usePage()
  const [state, dispatch] = useUser()
  const { loggedInUser, currentUserID } = state
  const [open, setOpen] = useState(false)
  const [isShowing, setIsShowing] = useState(false)
  const [menu, setMenu] = useState()
  const [name, setName] = useState('')
  // const [settingsOpen, setSettingsOpen] = useState(false)
  const [cartOpen, setCartOpen] = useState(false)
  const [anchor, setAnchor] = useState(null)
  const [mobileOpen, setMobileOpen] = useState(false)
  const [invites, setInvites] = useState([])
  const [inviteAnchor, setInviteAnchor] = useState(null)
  const [messages, setMessages] = useState([])
  const [messagesAnchor, setMessagesAnchor] = useState(null)
  const [messagesOpen, setMessagesOpen] = useState(false)
  const [inviteMenuOpen, setInviteMenuOpen] = useState(false)
  const showUserMenu = ['/', '/calendar', '/registry'].includes(path)
  const [TourOpen, setTourOpen] = useState(true)
  const [isFirstTime, setIsFirstTime] = useState(null)
  const { cart } = useCart()

  // Organization Tools
  const [organization, setOrganization] = useState(null)

  const getOrganizationInfo = async () => {
    const match = await getOrganization(loggedInUser.organizationId)
    setOrganization(match)
  }

  useEffect(() => {
    if (loggedInUser.organizationId) getOrganizationInfo()
  }, [])

  const closeTour = () => {
    removeFirstTimeLogin()
    setTourOpen(false)
    setIsShowing(false)
  }

  const removeFirstTimeLogin = async () => {
    const firstTime = await updateUser(loggedInUser.id, {
      isFirstLogin: false
    })
    if (firstTime) {
      Mixpanel.identify(loggedInUser.id)
    }
    setIsFirstTime(false)
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleInviteMenuOpen = (e: Event) => {
    setInviteAnchor(e.currentTarget)
    setInviteMenuOpen(true)
  }

  const handleInviteMenuClose = () => {
    setInviteAnchor(null)
    setInviteMenuOpen(false)
  }

  const handleMessagesMenuOpen = (e: Event) => {
    setMessagesAnchor(e.currentTarget)
    setMessagesOpen(true)
  }

  const handleMessagesMenuClose = () => {
    setMessagesAnchor(null)
    setMessagesOpen(false)
  }

  const handleMenuClick = event => setAnchor(event.currentTarget)
  const handleClose = () => setAnchor(null)
  const updateCurrentUser = id => {
    dispatch({ type: 'UPDATE_CURRENT', payload: id })
    handleClose()
  }

  const buildMenu = async () => {
    let supportMenu = [loggedInUser.id]
    if (loggedInUser.supportedUsers) {
      supportMenu = [...supportMenu, ...loggedInUser.supportedUsers]
    }
    const items = []
    for (const user of supportMenu) {
      const info = await getUserInfo(user)
      if (info && info.firstName) {
        if (user === currentUserID) {
          setName(`${info.firstName} ${info.lastName}`)
        }
        items.push(info)
      }
    }
    setMenu(items)
  }

  useEffect(() => {
    const getFirstTime = async () => {
      const info = await getUserInfo(loggedInUser.id)
      const isFirstLogin = await info.isFirstLogin
      setIsFirstTime(isFirstLogin)
    }
    getFirstTime()
  }, [setIsFirstTime, getUserInfo, updateUser])

  useEffect(() => {
    buildMenu()
  }, [currentUserID])

  useEffect(() => {
    pageDispatch({ type: 'UPDATE_CURRENT_PAGE', payload: path })
  }, [path])

  useEffect(() => {
    const unsubscribeInvitesAndUser = firestore()
      .doc(`users/${loggedInUser.id}`)
      .onSnapshot(async doc => {
        if (doc.exists) {
          let data = doc.data()

          const invitations = data.invitations

          let all = []
          if (invitations) {
            for (const invite of invitations) {
              const inviter = await getUserInfo(invite.id)
              invite.inviter = inviter
              all.push(invite)
            }
          }
          setInvites(all)

          // Update all other user information
          for (const key of Object.keys(data)) {
            const modifiedKeys = ['lastSignInTime', 'emailVerified']

            if (!modifiedKeys.includes(key)) loggedInUser[key] = data[key]
          }
        }
      })

    const unsubscribeMessages = firestore()
      .collection(`conversations`)
      .where('recipients', 'array-contains', loggedInUser.id)
      .onSnapshot(async querySnapshot => {
        const unreads = []

        const unreadUserMap = {}

        for (const doc of querySnapshot.docs) {
          if (doc.exists) {
            const conversation = doc.data()
            const allMessages = conversation.messages || []

            for (const message of allMessages) {
              if (
                !(loggedInUser.blockedUsers || []).includes(message.senderid)
              ) {
                const read = message.read
                for (const key of Object.keys(read)) {
                  if (key === loggedInUser.id && !read[key]) {
                    const unreadUserId = message.senderid

                    let unreadUserName = unreadUserMap[unreadUserId]

                    // If the user has not already been saved as the user of an unread message...
                    if (!unreadUserName) {
                      const unreadUser = await getUserInfo(unreadUserId)
                      unreadUserName = unreadUser
                        ? `${unreadUser.firstName} ${unreadUser.lastName}`
                        : DELETED_USER().firstName

                      unreadUserMap[unreadUserId] = unreadUserName
                    }

                    message.sender = unreadUserName

                    message.conversationId = doc.id
                    unreads.push(message)
                  }
                }
              }
            }
          }
        }

        unreads.sort((a, b) => {
          if (a.date < b.date) {
            return 1
          }

          if (a.date > b.date) {
            return -1
          }

          return 0
        })

        setMessages(unreads)
      })

    return () => {
      unsubscribeInvitesAndUser()
      unsubscribeMessages()
    }
  }, [loggedInUser.id])

  const acceptInvitation = (id: string, group: string) => {
    handleInviteMenuClose()
    return acceptUserInvitation(loggedInUser.id, id, group)
  }

  const denyInvitation = (id: string) => {
    handleInviteMenuClose()
    return denyUserInvitation(loggedInUser.id, id)
  }

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <Grid alignItems="center" container>
          <Grid item xs={3}>
            <UserOrgLogo
              organization={organization}
              loggedInUser={loggedInUser}
            />
          </Grid>
          <Grid item xs={9}>
            <Typography variant="h5" className={classes.familyProudTitle}>
              Family Proud
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <Box
              className={classes.nameMain}
              component="div"
              textOverflow="ellipsis"
              overflow="hidden">
              <b>
                {loggedInUser.firstName} {loggedInUser.lastName}
              </b>
            </Box>
          </Grid>
        </Grid>
        {/* <Typography variant="body2">
          <SignOutButton />
        </Typography> */}
      </div>

      <Divider />

      <List className={classes.tabs}>
        <ListItemLink to={routes.HOME} selected={path === routes.HOME}>
          <ListItemIcon>
            <HomeIcon className={classes.fill} />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemLink>
        <ListItemLink
          to={routes.RESOURCES}
          selected={path === routes.RESOURCES}>
          <ListItemIcon>
            <ResourcesIcon className={classes.fill} />
          </ListItemIcon>
          <ListItemText primary="Resources" />
        </ListItemLink>
        <ListItemLink to={routes.PEER} selected={path === routes.PEER}>
          <ListItemIcon>
            <PeerToPeerIcon className={classes.fill} />
          </ListItemIcon>
          <ListItemText primary="Peer-to-Peer" />
        </ListItemLink>
        <ListItemLink to={routes.CALENDAR} selected={path === routes.CALENDAR}>
          <ListItemIcon>
            <CalendarIcon className={classes.fill} />
          </ListItemIcon>
          <ListItemText primary="Calendar" />
        </ListItemLink>
        <ListItemLink to={routes.REGISTRY} selected={path === routes.REGISTRY}>
          <ListItemIcon>
            <RegistryIcon className={classes.fill} />
          </ListItemIcon>
          <ListItemText primary="Registry" />
        </ListItemLink>
        <ListItemLink
          to={routes.MESSAGING}
          selected={path === routes.MESSAGING}
          data-cy="Messages">
          <ListItemIcon data-cy="Messages">
            <DirectMessageIcon className={classes.fill} />
          </ListItemIcon>
          <ListItemText primary="Messages" />
        </ListItemLink>
      </List>
      <Divider />
      <List>
        <ListItemLink to={routes.INVITE} selected={path === routes.INVITE}>
          <ListItemIcon>
            <InviteIcon className={classes.fill} />
          </ListItemIcon>
          <ListItemText
            primary="Invite"
            primaryTypographyProps={{ className: classes.tabs }}
          />
        </ListItemLink>
        <ListItemLink to={routes.SETTINGS} selected={path === routes.SETTINGS}>
          <ListItemIcon>
            <SettingsIcon className={classes.fill} />
          </ListItemIcon>
          <ListItemText
            primary="Settings"
            primaryTypographyProps={{ className: classes.tabs }}
          />
        </ListItemLink>
        {loggedInUser.isAdmin && (
          <ListItemLink to={routes.ADMIN} selected={path === routes.ADMIN}>
            <ListItemIcon>
              <SupervisorAccountIcon className={classes.fill} />
            </ListItemIcon>
            <ListItemText
              primary="Admin"
              primaryTypographyProps={{ className: classes.tabs }}
            />
          </ListItemLink>
        )}
      </List>
    </div>
  )

  const renderInviteMenu = (
    <Menu
      anchorEl={inviteAnchor}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id="invite-menu"
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={inviteMenuOpen}
      onClose={handleInviteMenuClose}>
      {invites &&
        invites.map((invite, idx) => {
          return (
            <ListItem key={idx}>
              {invite.group} group invitation from {invite.inviter.firstName}{' '}
              {invite.lastName}
              <br />
              <Button
                onClick={() => acceptInvitation(invite.id, invite.group)}
                className={classes.green}>
                Accept
              </Button>{' '}
              <Button
                onClick={() => denyInvitation(invite.id)}
                className={classes.red}>
                Deny
              </Button>
            </ListItem>
          )
        })}
    </Menu>
  )

  const renderMessagesMenu = (
    <Menu
      anchorEl={messagesAnchor}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id="messages-menu"
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={messagesOpen}
      onClose={handleMessagesMenuClose}>
      {messages &&
        messages.map((message, idx) => {
          return (
            <MenuItem key={idx}>
              <Link
                to={`${routes.MESSAGING}/${message.conversationId}`}
                className={classes.messagesLink}>
                <p>
                  <b>From: {message.sender}</b>
                  <br />
                  {message.message}
                </p>
              </Link>
              <Divider />
            </MenuItem>
          )
        })}
    </Menu>
  )

  const dropdown = (
    <>
      {showUserMenu && menu && menu.length > 0 && (
        <>
          View User:{' '}
          <Chip
            data-cy="switch-user-menu-chip"
            icon={<KeyboardArrowDown />}
            label={name}
            size="small"
            onClick={handleMenuClick}
          />
          <Menu
            id="network-menu"
            anchorEl={anchor}
            open={Boolean(anchor)}
            onClose={handleClose}>
            <div className={classes.groupHeader}>My Profile</div>
            <MenuItem
              key={loggedInUser.id}
              onClick={() => updateCurrentUser(loggedInUser.id)}>
              <Avatar
                src={loggedInUser.img || FamilyIconMulti}
                className={classes.marginRight}
              />
              {''}
              {loggedInUser.firstName} {loggedInUser.lastName}
            </MenuItem>
            {loggedInUser.supportedUsers.length > 0 && (
              <div className={classes.groupHeader}>I Support</div>
            )}
            {menu &&
              menu.map(item => {
                return (
                  <div key={item.id}>
                    {item.id !== loggedInUser.id && (
                      <div>
                        <MenuItem
                          key={item.id}
                          onClick={() => updateCurrentUser(item.id)}>
                          <Avatar
                            src={item.img || FamilyIconMulti}
                            className={classes.marginRight}
                          />{' '}
                          {item.firstName} {item.lastName}
                        </MenuItem>
                      </div>
                    )}
                  </div>
                )
              })}
          </Menu>
          <div className={classes.grow} />
        </>
      )}
    </>
  )

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={`${classes.appBar} `}>
        <Toolbar className="app-bar">
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="Open Drawer"
            onClick={handleDrawerToggle}>
            <MenuIcon className={classes.fill} />
          </IconButton>
          <div className={classes.grow} />
          {dropdown}
          <div className={classes.sectionDesktop}>
            {invites.length > 0 && (
              <IconButton
                edge="end"
                aria-label="Notifications"
                onClick={handleInviteMenuOpen}>
                <Badge badgeContent={invites.length} color="primary">
                  <NotificationIcon className={classes.fill} />
                </Badge>
              </IconButton>
            )}
            {messages.length > 0 && (
              <IconButton
                edge="end"
                aria-label="Messages"
                onClick={handleMessagesMenuOpen}
                color="inherit">
                <Badge badgeContent={messages.length} color="primary">
                  <DirectMessageIcon className={classes.fill} />
                </Badge>
              </IconButton>
            )}
            <IconButton
              edge="end"
              data-cy="view-cart-button"
              aria-label="Cart"
              color="inherit"
              onClick={() => setCartOpen(true)}>
              <Badge badgeContent={cart.lineItems.length || 0} color="primary">
                <ShoppingCart className={classes.cart} />
              </Badge>
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            {invites.length > 0 && (
              <IconButton
                edge="end"
                aria-label="Notifications"
                onClick={handleInviteMenuOpen}>
                <Badge badgeContent={invites.length} color="primary">
                  <NotificationIcon className={classes.fill} />
                </Badge>
              </IconButton>
            )}
            {messages.length > 0 && (
              <IconButton
                edge="end"
                aria-label="Messages"
                onClick={handleMessagesMenuOpen}
                color="inherit">
                <Badge badgeContent={messages.length} color="primary">
                  <DirectMessageIcon className={classes.fill} />
                </Badge>
              </IconButton>
            )}
            <IconButton
              edge="end"
              data-cy="view-cart-button"
              aria-label="Cart"
              color="inherit"
              onClick={() => setCartOpen(true)}>
              <Badge badgeContent={cart.lineItems.length} color="primary">
                <ShoppingCart className={classes.cart} />
              </Badge>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {invites && renderInviteMenu}
      {messages && renderMessagesMenu}
      <nav className={`${classes.drawer} nav`} aria-label="Drawer">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true
            }}>
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open>
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={`${classes.content} main`}>{children}</main>
      <Cart open={cartOpen} closeCart={() => setCartOpen(false)} />
    </div>
  )
}

const tourStyle = {
  border: '3px solid theme.palette.primary.main',
  padding: '21px 62px'
}

export default withRouter(PrimaryHeader)
