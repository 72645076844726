import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import logger from 'redux-logger'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import rootReducer from './rootReducer'

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const dev = process.env.NODE_ENV !== 'production'

const store = configureStore({
  reducer: persistedReducer,
  middleware: [
    // Add dev. middleware
    ...(dev ? [logger] : [])
  ],
  devTools: dev,
})

const persistor = persistStore(store)


if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default
    store.replaceReducer(newRootReducer)
  })
}

export {store, persistor}
