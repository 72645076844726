// External Dependencies
import * as React from "react";
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Typography, useTheme } from "@material-ui/core";

// Local Dependencies
import clsx from "clsx";

const useStyles = makeStyles((theme) => createStyles({
    root: {
        color: theme.palette.text.secondary
    },
    flexParent: {
        display: "flex",
        padding: theme.spacing(3)
    },
    flexChild: {
        margin: "auto"
    },
    flexColumn: {
        flexDirection: "column"
    },
    icon: {
        fontSize: 'xxx-large'
    },
    spacedFlexChild: {
        margin: 'auto',
        padding: theme.spacing(3)
    }
}));

// User Ideas Screen
const IconMessageBlock = ({ message, Icon }) => {
    const classes = useStyles()
    const theme = useTheme()
    return (
        <div className={clsx(classes.root,classes.flexParent)}>
            <div className={classes.flexChild}>
                <div className={clsx(classes.flexParent, classes.flexColumn)}>
                    <Typography align="center">{message}</Typography>
                    <div className={classes.spacedFlexChild}>

                        <Icon className={classes.icon} />

                    </div>
                </div>
            </div>
        </div>


    )
}

export default IconMessageBlock;
