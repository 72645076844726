import React from 'react'
import {AuthProvider} from '../../context/auth'
import {UserProvider} from '../../context/user'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import {store, persistor} from '../../store'

function AppProvider({children}) {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AuthProvider>
          <UserProvider>{children}</UserProvider>
        </AuthProvider>
      </PersistGate>
    </Provider>
  )
}

export default AppProvider
