import React from 'react'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import FamilyIconMulti from '../../images/logo-brand-multi.png'
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined'
import { Button } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  notFound: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& > *': {
      margin: 10
    }
  },
  notFoundImage: {
    maxHeight: 300,
    maxWidth: 300
  },
  errorMessage: {
    fontSize: '2rem',
    textAlign: 'center',
    display: 'flex',
    marginBottom: 10,
    '& div': {
      marginLeft: 5
    }
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: '#F2A044',
    color: 'white'
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  }
}))

const NotFoundPage = () => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.notFound}>
        <div className={classes.errorMessage}>
          <ErrorOutlineOutlinedIcon fontSize="large" color="primary" />
          <div>Oh no! Page not found!</div>
        </div>
        <img className={classes.notFoundImage} src={FamilyIconMulti} />
        <Link className={classes.link} to="/">
          <Button
            data-cy="return-to-home-page"
            className={classes.button}
            variant="contained">
            Return To Homepage
          </Button>
        </Link>
      </div>
    </>
  )
}
export default NotFoundPage
