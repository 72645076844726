import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  org: [],
  covid: [],
  resources: [],
  lastUpdated: null
}

const resources = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    insertAllResources(state, action) {
      const { org, covid, resources } = action.payload
      state.org = org
      state.covid = covid
      state.resources = resources
      state.lastUpdated = Date.now()
    }
  }
})

export const { insertAllResources } = resources.actions

export default resources.reducer
