// @flow
import React, { useState, useEffect } from 'react'
import firebase from 'firebase'
import queryString from 'query-string'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Input from '@material-ui/core/Input'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'

import backgroundImage from '../../images/background-login.png'
import * as routes from '../../constants/routes'
import { generateToggledFunction } from '../../utils/misc'

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: '#E5E5E5',
      fontSize: '1.6rem',
      '& label.Mui-focused': {
        color: theme.palette.primary.main
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: theme.palette.primary.main
      }
    }
  },
  companyName: {
    fontSize: '4.0rem',
    textTransform: 'uppercase'
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    padding: '20px 10px',
    paddingTop: '100px',
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: 'white',
    width: 200,
    height: 200
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  signup: {
    margin: theme.spacing(1, 0, 1),
    borderRadius: '50px',
    fontSize: '1.6rem',
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50px',
    fontSize: '1.6rem',
    '&:hover': {
      backgroundColor: '#c18135',
      color: theme.palette.common.white
    }
  },
  error: {
    color: theme.palette.error.main
  }
}))

function PasswordReset(props: Object) {
  const { location } = props
  const { search } = location
  const values = queryString.parse(search)
  const [password, setPassword] = useState('')
  const [success, setSuccess] = useState(false)
  const [password2, setPassword2] = useState('')
  const [loading, setLoading] = useState(false)
  const [showForm, setShowForm] = useState(true)
  const [isInvalid, setIsInvalid] = useState(true)
  const [showPassword, setShowPassword] = useState(false)
  const [message, setMessage] = useState('')
  const classes = useStyles()

  const code = values.oobCode

  const onSubmit = async (event: Event) => {
    event.preventDefault()
    if (!isInvalid) {
      setLoading(true)
      await firebase
        .auth()
        .confirmPasswordReset(code, password)
        .then(() => {
          setMessage('Your password has been successfully changed')
          setSuccess(true)
        })
        .catch(err => setMessage(`There has been an error: ${err}`))
      
      setLoading(false)
    }
  }

  const verifyCode = () => {
    if (code) {
      firebase
        .auth()
        .verifyPasswordResetCode(code)
        .then(() => {
          setShowForm(true)
        })
        .catch(() => setShowForm(false))
    }
  }

  useEffect(() => {
    if (password === password2 && password.length > 5) {
      setIsInvalid(false)
    }
  }, [password, password2])

  useEffect(() => {
    if (code) {
      verifyCode()
    } else {
      setShowForm(false)
    }
  }, [code])

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h2" variant="h5">
            Reset Password
          </Typography>
          {message && <Typography variant="body2">{message}</Typography>}
          {showForm ? (
            <form
              className={classes.form}
              noValidate
              onSubmit={generateToggledFunction(e => onSubmit(e), isInvalid || success || loading)}>
              <FormControl fullWidth>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(event: Event) => setPassword(event.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={() => setShowPassword(!showPassword)}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl fullWidth>
                <InputLabel htmlFor="password">Re-Enter Password</InputLabel>
                <Input
                  id="password2"
                  type={showPassword ? 'text' : 'password'}
                  value={password2}
                  onChange={(event: Event) => setPassword2(event.target.value)}
                />
              </FormControl>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isInvalid || success || loading}>
                Submit
              </Button>
              <Button
                href={routes.SIGN_IN}
                fullWidth
                variant="outlined"
                className={classes.signup}
                color="primary">
                Back to Sign In
              </Button>
            </form>
          ) : (
            <Typography variant="body1">
              Your reset link has expired. Please{' '}
              <a href="/forgot">request a new link</a>.
            </Typography>
          )}
        </div>
      </Container>
    </>
  )
}

export default PasswordReset
