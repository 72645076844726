const palette = {
  // represents primary interface elements
  primary: {
    main: '#f7913b',
    contrast: '#fff'
  },
  primaryHighlight: {
    main: '#f2662d'
  },
  primaryCompliment: {
    main: '#003d59'
  },
  // represents secondary interface elements
  secondary: {
    main: '#fff'
  },
  secondary1: {
    main: '#109793'
  },
  secondary2: {
    main: '#f8bc2b'
  }
}

export default palette
