import firebase, { auth, analytics, store } from './firebase'
import { ErrorOutline } from '@material-ui/icons'
import { SEND_PASSWORD_CONFIRMATION } from '../constants/functions'

// Sign Up
export const doCreateUserWithEmailAndPassword = (email, password) =>
  auth.createUserWithEmailAndPassword(email, password).catch(function (error) {
    alert(error.message)
  })

// Sign In
export const doSignInWithEmailAndPassword = (email, password) =>
  auth.signInWithEmailAndPassword(email, password)

// Sign out
export const doSignOut = () => {
  localStorage.removeItem('userID')
  localStorage.removeItem('userType')
  auth.signOut()
}

export async function isExistingUser(email) {
  let isUser = false

  try {
    // Attempt signing in with the provided email and arbitrary password
    await auth.signInWithEmailAndPassword(email, '_')

    // Handle the case in which the user does sign in successfully with the arbitrary password
    await auth.signOut()

    isUser = true
  } catch ({ code }) {
    // Check for error codes that correspond to non-existent users
    isUser = !['auth/user-not-found', 'auth/invalid-email'].includes(code)
  }

  return isUser
}

const reauthenticate = currentPassword => {
  const currentUser = auth.currentUser
  const cred = firebase.auth.EmailAuthProvider.credential(
    currentUser.email,
    currentPassword
  )
  return currentUser
    .reauthenticateWithCredential(cred)
    .then(function () {
      // User re-authenticated.
      return
    })
    .catch(function (error) {
      // An error happened.
      return error
    })
}

// Password Reset
export const resetPassword = emailAddress => {
  return new Promise((resolve, reject) => {
    if (!emailAddress) {
      reject()
      return
    }

    const currentUser = auth.currentUser
    if (!currentUser) {
      reject()
      return
    }

    auth
      .sendPasswordResetEmail(emailAddress)
      .then(value => {
        analytics.logEvent('send_password_reset')
        resolve(value)
      })
      .catch(reason => {
        reject(reason)
      })
  })
}

// Password Change
export const changePassword = (currentPassword, password, user) => {
  return new Promise((resolve, reject) => {
    if (!password) {
      reject()
      return
    }

    const currentUser = auth.currentUser

    if (!currentUser) {
      reject()
      return
    }

    reauthenticate(currentPassword)
      .then(err => {
        if (err) {
          reject(err)
          return
        }
        currentUser
          .updatePassword(password)
          .then(value => {
            const ref = store.collection('users').doc(currentUser.uid)
            if (!ref) {
              reject()
              return
            }

            ref
              .update({
                lastPasswordChange: firebase.firestore.FieldValue.serverTimestamp()
              })
              .then(async (value) => {
                await passwordConfirmationEmail(user)
                analytics.logEvent('change_password')
                resolve(value)
              })
              .catch(reason => {
                reject(reason)
              })
          })
          .catch(reason => {
            reject(reason)
          })
      })
      .catch(reason => {
        reject(reason)
      })
  })
}

export const changeEmailAddress = (currentPassword, emailAddress) => {
  return new Promise((resolve, reject) => {
    if (!emailAddress) {
      reject()
      return
    }

    const currentUser = auth.currentUser
    if (!currentUser) {
      reject()
      return
    }

    reauthenticate(currentPassword).then(err => {
      if (err) {
        reject(err)
        return
      }
      currentUser
        .updateEmail(emailAddress)
        .then(value => {
          const ref = store.collection('users').doc(currentUser.uid)
          if (!ref) {
            reject()
            return
          }

          ref
            .update({
              email: emailAddress
            })
            .then(value => {
              analytics.logEvent('change_email')
              resolve(value)
            })
            .catch(reason => {
              reject(reason)
            })
        })
        .catch(reason => {
          reject(reason)
        })
    })
  })
}

export const passwordConfirmationEmail = ({ firstName, lastName, email }) => {
  return new Promise((resolve, reject) => {

    const currentUser = auth.currentUser
    if (!currentUser) {
      reject()
      return
    }

    const body = {
      user: `${firstName} ${lastName}`,
      email
    }
    fetch(SEND_PASSWORD_CONFIRMATION, {
      method: 'post',
      body: JSON.stringify(body)
    }).then(response => {

      if (response.ok) {
        resolve()
      } else {
        reject()
      }

    })

  })
}

export const verifyEmailAddress = () => {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser
    if (!currentUser) {
      reject()
      return
    }

    currentUser
      .sendEmailVerification()
      .then(value => {
        analytics.logEvent('verify_email')
        resolve(value)
      })
      .catch(reason => {
        reject(reason)
      })
  })
}
