const REGION = 'us-central1'
const PROJECT = process.env.REACT_APP_FIREBASE_PROJECT
const URL = `https://${REGION}-${PROJECT}.cloudfunctions.net`

export const SEND_INVITE = `${URL}/sendInvite`
export const GET_LOCATION = `${URL}/getLocation`
export const SEND_CALENDAR_NOTIFICATION = `${URL}/sendCalendarNotification`
export const SEND_FEEDBACK = `${URL}/sendJiraFeedback`
export const SIGNUP_NOTIFICATION = `${URL}/sendSignupNotification`
export const SLACK_FEEDBACK = `${URL}/sendSlackFeedback`
export const SOCIAL_WALL_NOTIFICATION = `${URL}/sendSocialWallNotification`
export const SEND_SMS = `${URL}/sendSMS`
export const DELETE_USER = `${URL}/deleteUser`
export const SEND_REGISTRY_NOTIFICATION = `${URL}/sendRegistryCreatedNotification`
export const ZIPCODE_GEOCODING = `${URL}/geocodeZip`
export const SEND_PASSWORD_CONFIRMATION = `${URL}/sendPasswordConfirmation`
export const VALIDATE_DOMAIN = `validateEmail`
export const RETRIEVE_ORGANIZATION = `retrieveOrganization`
export const SEND_ADD_TO_CART_NOTIFICATION = `${URL}/addToCartNotification`

