import React from 'react'

const MessagingContext = React.createContext()

function reducer(state, action) {
  const { type, payload } = action
  switch (type) {
    case 'UPDATE_SELECTED_CONVERSATION':
      return { ...state, conversation: payload }
    default:
      throw new Error('Invalid Action')
  }
}

const initialState = {
  conversation: {}
}

function MessagingProvider(props) {
  const { children } = props
  const [state, dispatch] = React.useReducer(reducer, initialState)

  return (
    <MessagingContext.Provider value={[state, dispatch]} {...props}>
      {children}
    </MessagingContext.Provider>
  )
}

function useMessaging() {
  const context = React.useContext(MessagingContext)
  if (context === undefined) {
    throw new Error(`useMessaging must be used within a MessagingProvider`)
  }

  return context
}

export { MessagingProvider, useMessaging }
