import React from 'react'
import { ListItem, Typography } from '@material-ui/core'
import { Remove as MinusIcon, Add as PlusIcon } from '@material-ui/icons'

const CartItem = ({
  classes,
  lineItemId,
  onUpdate,
  onRemove,
  productTitle,
  quantity,
  variant
}) => {
  return (
    <ListItem className={classes.listItem}>
      <div className={classes.imageContainer}>
        <img
          className={classes.image}
          src={variant.image.src}
          alt={productTitle}
        />
      </div>
      <div className={classes.titleSection}>
        <Typography variant="h6">
          {productTitle}{' '}
          <span className={classes.unitPrice}>${variant.price}</span>
        </Typography>
        <div className={classes.quantityContainer}>
          <div className={classes.quantityPicker}>
            <button
              onClick={() =>
                quantity === 1
                  ? onRemove(lineItemId)
                  : onUpdate({ id: lineItemId, quantity: quantity - 1 })
              }>
              <MinusIcon />
            </button>
            <div className={classes.quantityInput}>{quantity}</div>
            <button
              onClick={() =>
                onUpdate({ id: lineItemId, quantity: quantity + 1 })
              }>
              <PlusIcon />
            </button>
          </div>
          <button
            className={classes.removeButton}
            onClick={() => onRemove(lineItemId)}>
            remove
          </button>
        </div>
      </div>
      <Typography className={classes.price}>
        ${(variant.price * quantity).toFixed(2)}
      </Typography>
    </ListItem>
  )
}

export default CartItem
