export const SIGN_UP = '/signup'
export const SIGN_IN = '/'
export const SIGN_IN_USER = '/signin'
export const PASSWORD_FORGET = '/forgot'
export const HOME = '/'
export const ACCOUNT = '/account'
export const ADMIN = '/admin'
export const USER_PROFILE = '/user'
export const MESSAGING = '/messages'
export const CALENDAR = '/calendar'
export const SETTINGS = '/settings'
export const RESOURCES = '/resources'
export const PEER = '/peer'
export const REGISTRY = '/registry'
export const INVITE = '/invite'