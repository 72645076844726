import React from 'react'
import { CircularProgress, makeStyles } from '@material-ui/core'
import Loading from 'react-loading'
import palette from '../theme/palette'

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex", 
    height: "100%", 
  },
  flexChild: {
    margin: "auto"
  }
}))

const LazyFallback = () => {
  const classes = useStyles()
  return <div className={classes.root}>
    <Loading type="bars" color={palette.primaryCompliment.main} className={classes.flexChild} />
  </div>
}

export const Lazy = component => props => {
  const Component = React.lazy(() => import(`../components/${component}`))
  return (
    <React.Suspense fallback={<LazyFallback />}>
      <Component {...props} />
    </React.Suspense>
  )
}

