// @flow
import * as React from 'react'
import { usePage } from '../../context/page'
import PrimaryHeader from './PrimaryHeader'
import MessagingHeader from './MessagingHeader'

export const PRIMARY = 'primary'
export const MESSAGING = 'messaging'

function Header(props: { children: object, path: string, location: Object }) {
  const { children, path, location } = props
  const [state] = usePage()
  const { currentPage } = state
  const [variant, setVariant] = React.useState('')

  React.useEffect(() => {
    if (currentPage === 'messages') {
      setVariant(MESSAGING)
    } else {
      setVariant(PRIMARY)
    }
  }, [currentPage])

  if (variant === PRIMARY) {
    return <PrimaryHeader path={path}>{children}</PrimaryHeader>
  }

  if (variant === MESSAGING) {
    return (
      <MessagingHeader path={path} location={location}>
        {children}
      </MessagingHeader>
    )
  }

  return null
}

export default Header
