// @flow
import React, { useState, useEffect } from 'react'
import firebase from 'firebase'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'

import backgroundImage from '../../images/background-login.png'
import * as routes from '../../constants/routes'
import { generateToggledFunction } from '../../utils/misc'

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: '#E5E5E5',
      fontSize: '1.6rem',
      '& label.Mui-focused': {
        color: theme.palette.primary.main
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: theme.palette.primary.main
      }
    }
  },
  companyName: {
    fontSize: '4.0rem',
    color: theme.palette.primaryCompliment.main,
    marginTop: '25px'
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    padding: '20px 10px',
    paddingTop: '100px',
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%'
  },
  header: {
    color: theme.palette.primaryCompliment.main,
    marginTop: '12px',
    marginBottom: '12px'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: 'white',
    width: 200,
    height: 200
  },
  bigAvatar: {
    margin: theme.spacing(1),
    backgroundColor: 'white',
    width: 160,
    height: 160,
    padding: '5px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  signup: {
    margin: theme.spacing(1, 0, 1),
    borderRadius: '50px',
    fontSize: '1.6rem',
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50px',
    fontSize: '1.6rem',
    '&:hover': {
      backgroundColor: '#c18135',
      color: theme.palette.common.white
    }
  },
  error: {
    color: theme.palette.error.main
  },
  message: {
    marginTop: '10px'
  }
}))

function PasswordForget(props: Object) {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [isInvalid, setIsInvalid] = useState(false)
  const [message, setMessage] = useState('')
  const classes = useStyles()
  const onSubmit = async (event: Event) => {
    event.preventDefault()
    setLoading(true)
    await firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        setEmail('')
        setMessage('Password reset email sent. Please check your inbox.')
      })
      .catch(err =>
        setMessage(
          `We do not recognize the email address below.  If you believe this is an error, please contact us at info@familyproud.com.  Please click on the “back to sign in” button to go back to the homepage to create an account.`
        )
      )

    setLoading(false)

  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography className={classes.companyName} component="h1" variant="h5">
          <b>Family Proud</b>
        </Typography>
        <div className={classes.image}>
          <Avatar
            className={classes.bigAvatar}
            src={require('../../images/logo-brand-multi.png')}
          />
        </div>
        <Typography component="h2" variant="h5" className={classes.header}>
          Forgot Password
        </Typography>
        <Typography variant="body1">
          To reset your password, please enter your email address below.
        </Typography>
        {message && (
          <Typography className={classes.message} variant="body2">
            {message}
          </Typography>
        )}
        <form className={classes.form} noValidate onSubmit={generateToggledFunction(e => onSubmit(e), loading)}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isInvalid || loading}>
            Submit
          </Button>
          <Button
            href={routes.SIGN_IN}
            fullWidth
            variant="outlined"
            className={classes.signup}
            color="primary">
            Back to Sign In
          </Button>
        </form>
      </div>
    </Container>
  )
}

export default PasswordForget
