import React from 'react'
import { PageProvider } from '../../context/page'
import Header, { PRIMARY, MESSAGING } from '../Header'
import MobileNav from '../MobileNav'

export default function Layout({ path, location, children }) {
  const headerVariant = path === 'messages' ? MESSAGING : PRIMARY

  return (
    <>
      <PageProvider path={path}>
        <Header path={path} variant={headerVariant} location={location} className='.first-step'>
          {children}
        </Header>
        <MobileNav path={path} />
      </PageProvider>
  </>
  )
}