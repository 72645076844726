import * as React from 'react'
import { useState, useEffect } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Avatar from '@material-ui/core/Avatar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Skeleton from '@material-ui/lab/Skeleton'
import { KeyboardArrowLeft, Forum as ForumIcon } from '@material-ui/icons'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import WarningIcon from '@material-ui/icons/Warning'
import UserOrgLogo from '../UserOrgLogo'

import FamilyIconMulti from '../../images/logo-brand-multi.png'
import FamilyIconWhite from '../../images/logo-brand-white.png'
import { MenuIcon } from '../../icons'
import { useStyles } from './styles'
import { useMessaging } from '../../context/messaging'
import { useUser } from '../../context/user'
import { usePage } from '../../context/page'
import { getConversation } from '../../functions/messaging'
import { getUserInfo } from '../../functions/user'
import { NewIcon } from '../../icons'
import firebase from 'firebase'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import {
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Badge
} from '@material-ui/core'
import IconMessageBlock from '../IconMessageBlock'
import { DELETED_USER } from '../../constants/messaging'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { getOrganization } from '../../functions/organizationUtils'
import Box from '@material-ui/core/Box'

const MobileAvatar = withStyles(theme => ({
  root: {
    top: 0,
    right: 0,
    width: 26,
    height: 26,
    border: `1px solid ${theme.palette.background.paper}`
  }
}))(Avatar)

function MessagingHeader(props) {
  const { container, path, location, children } = props
  const classes = useStyles()
  const [, pageDispatch] = usePage()
  const [state] = useUser()
  const { loggedInUser } = state
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [conversations, setConversations] = React.useState()
  const [conversationCount, setConversationCount] = React.useState(
    loggedInUser.conversations ? loggedInUser.conversations.length : 0
  )
  const [selectedConversation, setSelectedConversation] = React.useState(null)
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const loadConversationData = async data => {
    const loadedConversations = []
    const userMap = {}

    await Promise.all(
      data.map(async id => {
        const result = await getConversation(id)
        if (result) {
          const users = []

          let numBlocked = 0
          let numOtherRecipients = result.recipients.length - 1
          const blocked = []
          const blockedBy = []

          await Promise.all(
            result.recipients.map(async user => {
              if (user !== loggedInUser.id) {
                let mappedUser = userMap[user]

                if (!mappedUser) {
                  const userResult = await getUserInfo(user)

                  userMap[user] = userResult ? userResult : DELETED_USER()

                  mappedUser = userMap[user]

                  if (!userResult) {
                    users.push(mappedUser)
                    return
                  } else mappedUser = userMap[user]
                }

                users.push(mappedUser)

                // Determines if the conversation recipient is blocked by/or has blocked the current user
                if ((loggedInUser.blockedUsers || []).includes(user)) {
                  numBlocked++
                  blocked.push(mappedUser)
                }

                if ((mappedUser.blockedUsers || []).includes(loggedInUser.id)) {
                  numBlocked++
                  blockedBy.push(mappedUser)
                }
              }
            })
          )

          // If all of a conversation's users are blocked, don't load the conversation
          if (numBlocked !== numOtherRecipients) {
            loadedConversations.push({
              id,
              users,
              updated: result.updated,
              blockedUsers: blocked
            })
          }
        }
      })
    )

    // Sort conversations by most recently updated
    loadedConversations.sort((a, b) => {
      if (a.updated > b.updated) {
        return -1
      }

      if (a.updated < b.updated) {
        return 1
      }

      if (a.updated === b.updated) {
        return 0
      }
    })

    setConversations(loadedConversations)
    setConversationCount(loadedConversations.length)
  }

  // Organization Tools
  const [organization, setOrganization] = useState(null)

  const getOrganizationInfo = async () => {
    const match = await getOrganization(loggedInUser.organizationId)
    setOrganization(match)
  }

  useEffect(() => {
    if (loggedInUser.organizationId) getOrganizationInfo()
  }, [])

  React.useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(`users`)
      .doc(loggedInUser.id)
      .onSnapshot(doc => {
        if (doc.exists) {
          const conversations = doc.data().conversations

          if (conversations) {
            loadConversationData(conversations)
          }
        }
      })

    return () => unsubscribe()
  }, [loggedInUser.id])

  React.useEffect(() => {
    pageDispatch({ type: 'UPDATE_CURRENT_PAGE', payload: path })
  }, [path])

  React.useEffect(() => {
    const locationPieces = location.pathname.split('/')
    if (locationPieces.length > 2) {
      setSelectedConversation(locationPieces[2])
    }
  }, [location])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={classNames(classes.appBar, classes.messagingBar)}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="Open Drawer"
            onClick={handleDrawerToggle}>
            <MenuIcon className={classes.menuIcon} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="Messaging Drawer">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{ paper: classes.drawerPaper }}
            ModalProps={{ keepMounted: true }}>
            <div className={classes.toolbar}>
              <Grid alignItems="center" container>
                <Grid item xs={3}>
                  <UserOrgLogo
                    organization={organization}
                    loggedInUser={loggedInUser}
                  />
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="h5" className={classes.familyProudTitle}>
                    Family Proud
                  </Typography>
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid item xs={3}>
                  <Link to="/">
                    <IconButton>
                      <KeyboardArrowLeft className={classes.backArrow} />
                    </IconButton>
                  </Link>
                </Grid>
                <Grid item xs={9}>
                  <Box
                    className={classes.name}
                    component="div"
                    textOverflow="ellipsis"
                    overflow="hidden">
                    <b>
                      {loggedInUser.firstName} {loggedInUser.lastName}
                    </b>
                  </Box>
                </Grid>
              </Grid>
            </div>
            <Divider />
            <MessageList
              conversations={conversations}
              count={conversationCount}
            />
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{ paper: classes.drawerPaper }}
            variant="permanent"
            open>
            <div className={classes.toolbar}>
              <Grid alignItems="center" container>
                <Grid item xs={3}>
                  <UserOrgLogo
                    organization={organization}
                    loggedInUser={loggedInUser}
                  />
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="h5" className={classes.familyProudTitle}>
                    Family Proud
                  </Typography>
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid item xs={3}>
                  <Link to="/">
                    <IconButton>
                      <KeyboardArrowLeft className={classes.backArrow} />
                    </IconButton>
                  </Link>
                </Grid>
                <Grid item xs={9}>
                  <Box
                    className={classes.name}
                    component="div"
                    textOverflow="ellipsis"
                    overflow="hidden">
                    <b>
                      {loggedInUser.firstName} {loggedInUser.lastName}
                    </b>
                  </Box>
                </Grid>
              </Grid>
            </div>
            <Divider />
            <MessageList
              conversations={conversations}
              selectedConversation={selectedConversation}
              count={conversationCount}
            />
          </Drawer>
        </Hidden>
      </nav>
      <main className={classNames(classes.content, classes.messagingContent)}>
        {children}
      </main>
    </div>
  )
}

function BlockedUserWarningModal({ open, users, handleCancel, handleAccept }) {
  const theme = useTheme()
  const classes = useStyles()
  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>
        <WarningIcon className={classes.warningIcon} />
        &nbsp;This conversation contains blocked users.
      </DialogTitle>

      <DialogContent>
        {users
          .filter(user => user !== null)
          .map(user => (
            <React.Fragment key={`${user.id}-blocked`}>
              <ListItem>
                <ListItemIcon>
                  <React.Fragment>
                    <Avatar
                      src={user.img || FamilyIconMulti}
                      key={`${user.id}-blocked-avatar`}
                    />
                  </React.Fragment>
                </ListItemIcon>
                <ListItemText className={classes.listItemText}>
                  <React.Fragment key={`${user.id}-blocked-name`}>
                    {user.firstName} {user.lastName}
                  </React.Fragment>
                </ListItemText>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="default">
          Cancel
        </Button>
        <Button onClick={handleAccept} color="primary">
          Enter Anyway
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function MessageList({ conversations = [], selectedConversation, count = 0 }) {
  const classes = useStyles()
  const theme = useTheme()
  const [state, dispatch] = useMessaging()

  const initialWarningModalState = {
    open: false,
    users: [],
    conversation: null
  }
  const [warningModal, setWarningModal] = React.useState(
    initialWarningModalState
  )
  const { conversation } = state
  const updateConversation = conv => {
    dispatch({
      type: 'UPDATE_SELECTED_CONVERSATION',
      payload: conv
    })
  }

  const updateBlockedConversation = conv => {
    // Trigger modal warning user that the selected conversations contains blocked users
    if (conv.blockedUsers.length) {
      setWarningModal({
        open: true,
        users: conv.blockedUsers,
        conversation: conv
      })
    } else {
      updateConversation(conv)
    }
  }

  const handleCancelWarningModal = () =>
    setWarningModal(initialWarningModalState)

  const handleAcceptWarningModal = () => {
    updateConversation(warningModal.conversation)
    setWarningModal(initialWarningModalState)
  }

  React.useEffect(() => {
    if (conversations && selectedConversation) {
      const selected = conversations.filter(
        conv => conv.id === selectedConversation
      )
      if (selected.length === 1) {
        updateConversation(selected[0])
      }
    }
  }, [conversations, selectedConversation])
  return (
    <>
      <BlockedUserWarningModal
        open={warningModal.open}
        users={warningModal.users}
        handleCancel={handleCancelWarningModal}
        handleAccept={handleAcceptWarningModal}
      />
      <ListItem button onClick={() => updateConversation({ id: 0 })}>
        <ListItemText>New Message</ListItemText>
        <ListItemIcon>
          <NewIcon className={classes.menuIcon} />
        </ListItemIcon>
      </ListItem>
      <Divider />
      <List classes={{ root: classes.messageList }}>
        {conversations.length ? (
          conversations.map(conv => {
            const { id, users } = conv
            const allUsers = users.filter(user => user !== null)
            return (
              <React.Fragment key={`conversation-${id}`}>
                <ListItem
                  button
                  onClick={
                    id !== conversation.id
                      ? () => updateBlockedConversation(conv)
                      : null
                  }
                  selected={id === conversation.id}
                  data-cy="conversation-block">
                  <ListItemIcon>
                    <React.Fragment>
                      {allUsers.map((user, idx) => {
                        if (user) {
                          return (
                            <Avatar
                              src={user.img || FamilyIconMulti}
                              key={`${user.id}-${id}`}
                              className={`${
                                idx > 0 ? classes.avatarMultiple : ''
                              } ${
                                user.img ? classes.avatar : classes.fpAvatar
                              }`}
                            />
                          )
                        }

                        return null
                      })}
                    </React.Fragment>
                  </ListItemIcon>
                  <ListItemText
                    className={
                      allUsers.length > 1
                        ? classes.multiListItemText
                        : classes.listItemText
                    }>
                    {allUsers.map((user, idx) => {
                      if (user) {
                        return (
                          <>
                            <span
                              className={classes.username}
                              key={`${user.id}-${id}-conversation-list`}>
                              {user.firstName}
                            </span>
                            <span className={classes.usernameDelimiter}>
                              {allUsers.length > 1 &&
                                idx < allUsers.length - 1 &&
                                ', '}
                            </span>
                          </>
                        )
                      }

                      return null
                    })}
                  </ListItemText>
                </ListItem>
                <Divider />
              </React.Fragment>
            )
          })
        ) : count !== 0 ? (
          // Show Skeleton
          Array.from(Array(count)).map((_, idx) => (
            <React.Fragment key={idx}>
              <ListItem>
                <ListItemIcon>
                  <Skeleton variant="circle">
                    <Avatar />
                  </Skeleton>
                </ListItemIcon>
                <ListItemText>
                  <Skeleton
                    width="100%"
                    height={`${theme.typography.body1.lineHeight} rem`}
                  />
                </ListItemText>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))
        ) : (
          <IconMessageBlock
            message={'No conversations were found.'}
            Icon={ForumIcon}
          />
        )}
      </List>
    </>
  )
}

export default MessagingHeader
