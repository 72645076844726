import React from 'react'
import { List, ListItem, ListItemIcon, Avatar, ListItemText, Paper, ListItemSecondaryAction, Checkbox } from '@material-ui/core'

export default function OrganizationBlock({
    organization: {
        image,
        name = 'Unnamed Organization',
    } = {
        image: '',
        name: 'Organization Name',
    },
    checked = false
}) {
    return (
        <List>
            <ListItem>
                <ListItemIcon>
                    <Avatar src={image}>{name.charAt(0)}</Avatar>
                </ListItemIcon>
                <ListItemText primary={name} />
                {
                    checked ? <ListItemSecondaryAction>
                        <Checkbox
                            edge="end"
                            checked={true}
                            color='primary'
                        />
                    </ListItemSecondaryAction>
                        : ''
                }
            </ListItem>
        </List>
    )
} 