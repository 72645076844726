// @flow
import { firestore } from 'firebase/app'
import { shopifyClient as shopify } from '../context/shopify'

class RegistryClient {
  async listProducts() {
    let products = {}
    try {
      products = await shopify.product.fetchAll()
    } catch (err) {
      return {
        status: 'error',
        msg: err
      }
    }

    return {
      status: 'success',
      products
    }
  }

  async getProduct(id: String) {
    let product = {}
    try {
      product = await shopify.product.fetch(id)
    } catch (err) {
      return {
        status: 'error',
        msg: err
      }
    }

    return {
      status: 'success',
      product
    }
  }

  async getUserRegistry(userid: String) {
    return firestore()
      .collection('registry')
      .doc(userid)
      .set({}, { merge: true })
      .then(doc => {
        if (doc.exists) {
          return doc.data()
        }

        return null
      })
  }

  async updateUserRegistry(userid: String, registryData: Object) {
    return firestore()
      .collection('registry')
      .doc(userid)
      .update(registryData)
      .then(() => true)
      .catch(error => error)
  }

  removeItemFromRegistry(userid: String, productToRemove: Object) {
    return firestore()
      .collection('registry')
      .doc(userid)
      .update('products', firestore.FieldValue.arrayRemove(productToRemove))
  }

  updateItemFromRegistry(userid: String, newProduct: Object, allProducts) {
    return firestore()
      .collection('registry')
      .doc(userid)
      .update(
        'products',
        allProducts.map(existingProduct => {
          const { productId, variantId } = existingProduct
          if (
            productId === newProduct.productId &&
            variantId === newProduct.variantId
          ) {
            return newProduct
          }
          return existingProduct
        })
      )
  }
}

export default RegistryClient
