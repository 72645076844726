const hasNumber = value => {
  return new RegExp(/[0-9]/).test(value)
}

const hasMixed = value => {
  return new RegExp(/[a-z]/).test(value) && new RegExp(/[A-Z]/).test(value)
}

const hasSpecial = value => {
  return new RegExp(/[!#@$%^&*)(+=._-]/).test(value)
}

export const createPasswordLabel = value => {
  switch (value.score) {
    case 0:
      return 'Weak'
    case 1:
      return 'Weak'
    case 2:
      return 'Fair'
    case 3:
      return 'Good'
    case 4:
      return 'Strong'

    default:
      return 'Weak'
  }
}

export const strengthIndicator = value => {
  let strengths = 0

  if (value !== undefined) {
    if (value.length > 5) strengths++

    if (hasNumber(value)) strengths++

    if (hasSpecial(value)) strengths++

    if (hasMixed(value)) strengths++
  }

  return strengths
}
