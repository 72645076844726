import React from 'react'
import firebase from 'firebase'
import { doSignInWithEmailAndPassword, doSignOut } from '../firebase/auth'
import { getUserInfo } from '../functions/user'
import Loading from 'react-loading'
import { makeStyles, useTheme } from '@material-ui/core'
import palette from '../theme/palette'

const AuthContext = React.createContext()

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100vh',
    backgroundColor: theme.palette.backgroundColor
  },
  flexChild: {
    margin: 'auto'
  }
}))

function AuthProvider(props) {
  const classes = useStyles()
  const theme = useTheme()

  const [state, setState] = React.useState(() => {
    const user = firebase.auth().currentUser

    return { initializing: !user, loggedInUser: null, user }
  })

  async function onChange(user) {
    let loggedInUser = null
    if (user) {
      loggedInUser = await getUserInfo(user.uid, true)
      loggedInUser.emailVerified = user.emailVerified
      loggedInUser.lastSignInTime = user.metadata.lastSignInTime
    }

    setState({ initializing: false, user, loggedInUser })
  }

  React.useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(onChange)
    return () => unsubscribe()
  }, [])

  const login = async (username, password) => {
    return doSignInWithEmailAndPassword(username, password)
  }
  const logout = () => doSignOut()

  if (state.initializing) {
    return (
      <div className={classes.root}>
        <span className={classes.flexChild}>
          <Loading type="bars" color={palette.primaryCompliment.main} />
        </span>
      </div>
    )
  }

  return (
    <AuthContext.Provider value={{ state, login, logout }} {...props}>
      {props.children}
    </AuthContext.Provider>
  )
}

const useAuth = () => {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within an AuthProvider`)
  }

  return context
}

export { AuthProvider, useAuth }
