import { makeStyles } from '@material-ui/core/styles'

const drawerWidth = 240

export const useStyles = makeStyles(theme => ({
  cart: {
    fill: '#4f4f4f',
  },
  fill: {
    fill: theme.palette.primaryCompliment.main,
  },
  green: {
    color: 'green',
  },
  groupHeader: {
    display: 'flex',
    lineHeight: '14px',
    textTransform: 'uppercase',
    margin: '5px',
    fontWeight: 'bold',
  },
  marginRight: {
    marginRight: '10px',
    marginTop: '10px',
  },
  red: {
    color: 'red',
  },
  tabs: {
    color: `${theme.palette.primaryCompliment.main} !important`,
    '& svg *': {
      fill: theme.palette.primaryCompliment.main,
    },
    '*': {
      color: `${theme.palette.primaryCompliment.main} !important`,
    },
  },

  familyProudTitle: {
    paddingLeft: '10px',
  },

  orgContainer: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  '@global': {
    '.MuiBadge-anchorOriginBottomRightCircle': {
      bottom: '0%',
    },
    '.MuiListItemIcon-root': {
      paddingLeft: '8px',
    },
    '.MuiListItemText-root': {
      paddingLeft: '6px',
    },
  },
  messageList: {
    overflow: 'auto',
  },
  name: {
    marginLeft: '8px',
  },
  nameMain: {
    textAlign: 'center',
    fontSize: '14px',
    marginTop: '5px',
  },
  backArrow: {
    fill: theme.palette.secondary.main,
  },
  icon: {
    width: '45px',
    height: '45px',
    padding: '5px',
  },
  header: {
    color: theme.palette.primary.contrastText,
  },
  root: {
    display: 'flex',
    backgroundColor: '#FBFDFF',
  },
  grow: {
    flexGrow: 1,
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    marginLeft: drawerWidth,
    backgroundColor: '#FFF',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  messagingBar: {},
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  menuIcon: {
    fill: '#4F4F4F',
  },
  toolbar: {
    ...theme.mixins.toolbar,
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2),
    textAlign: 'left',
    color: 'white',
    minHeight: 'unset !important',
  },
  messagingToolbar: {
    ...theme.mixins.toolbar,
    padding: theme.spacing(1),
    alignItems: 'center',
    background: theme.palette.primary.main,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  content: {
    marginTop: 64,
    height: 'calc(100vh - 64px)',
    [theme.breakpoints.down('xs')]: {
      marginTop: 55,
      height: 'calc(100vh - 55px)',
    },
    flexGrow: 1,
    padding: theme.spacing(3),
    width: 'calc(100vw - 240px)',
    marginBottom: 0,
  },
  messagingContent: {
    padding: 0,
    left: drawerWidth,
    [theme.breakpoints.only('xs')]: {
      left: 0,
    },
  },
  avatarMultiple: {
    marginLeft: -25,
    boxShadow: '0 0 2px rgba(0,0,0,0.5)',
  },
  messagesLink: {
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  avatar: {
    background: theme.palette.secondary.main,
  },
  fpAvatar: {
    background: theme.palette.secondary.main,
    width: '45px',
    height: '45px',
    padding: '5px',
  },
  warningIcon: {
    color: theme.palette.warning.main,
    verticalAlign: 'text-bottom',
  },
  listItemText: {
    marginLeft: 'auto',
  },
  multiListItemText: {
    marginLeft: 10,
  },
  username: {
    display: 'inline-block',
    textOverflow: 'ellipsis',
    maxWidth: '100px',
    overflow: 'hidden',
  },
  usernameDelimiter: {
    verticalAlign: 'top',
  },
}))
